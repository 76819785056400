import moment from "moment";
import { CONNECTED_TCP_SERVER, CLOSED_TCP_SERVER, CONNECTION_ERROR_TCP_SERVER, NEW_LOG } from "../Actions/TcpActions";
import { ERROR, GET_STATE_FROM_API, ENTEGRATIONS, GET_ALL_LOGS } from "../Actions/ApiActions";

export const initialState = {
  tcp_connected: 1,
  integrations: {},
  current_state: {
    IsTwinCatConnectionEstablished: false,
    SeatBelt: {
      lock1: false,
      lock2: false,
      lock3: false,
      lock4: false,
    },
    Engines: {
      X: { Name: "X" },
      Y: { Name: "Y" },
      Z: { Name: "Z" },
    },
    VrDevice: {
      connection: false,
      running: false
    }
  },
  logs: [],
};

export default function SensorReducer(state = initialState, action) {
  switch (action.type) {
    case ERROR:
      return { ...state, error: action.payload };

    case GET_STATE_FROM_API:
      if (Object.keys(action.payload.Engines).length !== 0) {
        return { ...state, current_state: action.payload };
      } else {
        let newState = action.payload;
        newState.Engines = state.current_state?.Engines;
        return { ...state, current_state: newState };
      }
    /*case NEW_ENGINE_AXIS:
      let new_state = state.current_state;
      Object.keys(action.payload).forEach((key) => {
        new_state.Engines[key].CurrentPosition = parseFloat(action.payload[key]);
      });
      return { ...state, current_state: new_state };*/

    case NEW_LOG:
      const logs = state.logs;
      let data = action.payload;
      data.TimeGenerated = moment(new Date(data.TimeGenerated)).format("DD/MM/YYYY HH:mm:ss");
      const newLog = [data].concat(logs);
      return { ...state, logs: newLog };

    case GET_ALL_LOGS:
      return { ...state, logs: action.payload };

    case ENTEGRATIONS:
      return { ...state, integrations: action.payload };

    case CONNECTED_TCP_SERVER:
      return { ...state, websocket: action.payload, tcp_connected: 2 };

    case CLOSED_TCP_SERVER:
      return { ...state, tcp_connected: 0 };

    case CONNECTION_ERROR_TCP_SERVER:
      return { ...state, tcp_connected: 0 };

    default:
      return state;
  }
}
