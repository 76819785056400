/* eslint-disable no-unused-vars */
import { Button, Col, Row } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
import { PlusCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { CalibrateAxis, DisableExactPositionAxis, EnableExactPositionAxis, powerOffAxis, powerOnAxis, ResetErrorAxis, SetZeroAxis } from "../Redux/Actions/ApiActions";
import { NeuButton, NeuDiv } from "neumorphism-react";
import { connectControlPanel, sendMotorCommand } from "../Redux/Actions/TcpActions";

const style = {
  height: "30%", // we can control scene size by setting container dimensions
  minHeight: "400px",
  outline: "none",
};

class SimulatorAnimation extends Component {
  angleTransformer(angle) {
    return (Math.PI / 180) * angle;
  }

  componentDidMount = () => {
    this.sceneSetup();
    this.addLights();
    this.loadTheModel("obje1", { x: 0, y: 0, z: 0 });
    //this.loadTheModel("obje2", { x: 0, y: 0, z: 0 });
    this.startAnimationLoop();
    window.addEventListener("resize", this.handleWindowResize);
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowResize);
    window.cancelAnimationFrame(this.requestID);
    this.controls.dispose();
  }

  /*componentDidUpdate() {
    if (this.state?.model) {
      this.state.model.getObjectByName("Axis_1").rotateY((Math.PI / 180) * 15);
      this.state.model.getObjectByName("Axis_2").rotateZ((Math.PI / 180) * 15);
      this.state.model.getObjectByName("Axis_3").rotateX((Math.PI / 180) * 15);
    }
  }*/

  // Standard scene setup in Three.js. Check "Creating a scene" manual for more information
  // https://threejs.org/docs/#manual/en/introduction/Creating-a-scene
  sceneSetup = () => {
    // get container dimensions and use them for scene sizing
    const width = this.mount.clientWidth;
    const height = this.mount.clientHeight;

    this.scene = new THREE.Scene();
    this.camera = new THREE.PerspectiveCamera(
      45, // fov = field of view
      width / height, // aspect ratio
      1, // near plane
      1000 // far plane
    );
    this.camera.position.y = 130;
    this.camera.position.x = 0;
    this.camera.position.z = 250; // is used here to set some distance from a cube that is located at z = 0

    var axesHelper = new THREE.AxesHelper(50);
    this.scene.add(axesHelper);

    // OrbitControls allow a camera to orbit around the object
    // https://threejs.org/docs/#examples/controls/OrbitControls
    this.controls = new OrbitControls(this.camera, this.mount);
    this.renderer = new THREE.WebGLRenderer();

    this.renderer.setClearColor(localStorage.getItem("mode") === "dark" ? "#1d1f21" : "white");
    this.renderer.setSize(width, height);
    this.mount.appendChild(this.renderer.domElement); // mount using React ref
  };

  // Code below is taken from Three.js OBJ Loader example
  // https://threejs.org/docs/#examples/en/loaders/OBJLoader
  loadTheModel = (name, position) => {
    // instantiate a loader
    const fbxLoader = new FBXLoader();

    fbxLoader.load(
      "SimulatorX.fbx",
      (object) => {
        object.name = name;
        this.scene.add(object);

        // get the newly added object by name specified in the OBJ model (that is Elephant_4 in my case)
        // you can always set console.log(this.scene) and check its children to know the name of a model
        const el = this.scene.getObjectByName(name);

        // change some custom props of the element: placement, color, rotation, anything that should be
        // done once the model was loaded and ready for display
        //object.rotateY(3.1415);
        this.state.model.getObjectByName("Axis_1").rotation.set(new THREE.Vector3(Math.PI / 2, Math.PI / 2, Math.PI / 2));
        this.state.model.getObjectByName("Axis_2").rotation.set(new THREE.Vector3(0, -Math.PI, (Math.PI / 2) * 3));
        this.state.model.getObjectByName("Axis_3").rotation.set(new THREE.Vector3(Math.PI / 2, 0, Math.PI / 2));

        el.position.set(position.x, position.y, position.z);
        // make this element available inside of the whole component to do any animation later
        this.setState({ model: el });
      },
      // called when loading is in progresses
      (xhr) => {
        const loadingPercentage = Math.ceil((xhr.loaded / xhr.total) * 100);
        // update parent react component to display loading percentage
        if (loadingPercentage === 100) this.setState({ loaded: true });
        this.props.onProgress(loadingPercentage);
      },
      // called when loading has errors
      (error) => {
        console.log("An error happened:" + error);
      }
    );

    // load a resource
  };

  // adding some lights to the scene
  addLights = () => {
    const lights = [];

    // set color and intensity of lights
    lights[0] = new THREE.HemisphereLight(0xffffbb, 0x080820, 1);
    lights[1] = new THREE.PointLight(0xffffff, 1, 0);
    lights[2] = new THREE.PointLight(0xffffff, 1, 0);
    lights[3] = new THREE.PointLight(0xffffff, 1, 0);

    // place some lights around the scene for best looks and feel
    lights[0].position.set(0, 2000, 0);
    lights[1].position.set(1000, 2000, 1000);
    lights[2].position.set(-1000, -2000, -1000);
    lights[3].position.set(0, 2000, 0);

    this.scene.add(lights[0]);
    this.scene.add(lights[1]);
    this.scene.add(lights[2]);
    this.scene.add(lights[3]);
  };

  startAnimationLoop = () => {
    // slowly rotate an object
    this.renderer.render(this.scene, this.camera);
    // The window.requestAnimationFrame() method tells the browser that you wish to perform
    // an animation and requests that the browser call a specified function
    // to update an animation before the next repaint
    this.requestID = window.requestAnimationFrame(this.startAnimationLoop);
  };

  handleWindowResize = () => {
    const width = this.mount.clientWidth;
    const height = this.mount.clientHeight;

    this.renderer.setSize(width, height);
    this.camera.aspect = width / height;

    // Note that after making changes to most of camera properties you have to call
    // .updateProjectionMatrix for the changes to take effect.
    this.camera.updateProjectionMatrix();
  };

  render() {
    return <div style={style} ref={(ref) => (this.mount = ref)} />;
  }
}

class Container extends React.Component {
  constructor(props) {
    super(props);
    this.divColor = props.theme.isDark ? "#1a1a1a" : "#FFFFFF";
    this.state = {
      isMounted: true,
      controlRef: null,
      loadingPercentage: 0,
      powerOn: false,
      exactPosition: false,
    };

    connectControlPanel().then((ref) => {
      this.setState({ controlRef: ref });
    });
  }

  componentDidMount() {
    if (this.props.theme.isDark) {
      require("antd/dist/antd-with-locales.min");
    } else {
      require("antd/dist/antd-with-locales.min");
    }
  }

  componentDidUpdate(prevProps) {}

  renderEngines() {
    let { selectedEngine } = this.props;
    let { Engines } = this.props.current_state;
    return (
      <Col xs={12} sm={12} md={12} lg={12} key={selectedEngine}>
        <NeuDiv
          color={this.divColor}
          style={{
            width: "100%",
            display: "flex",
            padding: "5%",
            flexDirection: "column",
            borderRadius: "30px",
          }}
        >
          <p>
            <b>{"Engine " + selectedEngine + " "}</b>

            { String(Engines[selectedEngine].CurrentPosition).slice(0, 6)+ "°"}
          </p>
          <p>
            <b>Powered: </b> {String(Engines[selectedEngine].Powered)}
          </p>
          {/*<img
            style={{
              alignSelf: "center",
              marginLeft: "10px",
              //transform: "translateX(-50%)",
              maxWidth: "30%",
            }}
            src={require("../images/electric-motor.png")}
          />*/}
      
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              onTouchStart={() => {
                sendMotorCommand(
                  this.state.controlRef,
                  selectedEngine,
                  "ManualControlDown",
                  true
                );
              }}
              onTouchEnd={() => {
                sendMotorCommand(
                  this.state.controlRef,
                  selectedEngine,
                  "ManualControlDown",
                  false
                );
              }}
              onMouseDown={() => {
                sendMotorCommand(this.state.controlRef, selectedEngine, "ManualControlDown", true);
              }}
              onMouseUp={() => {
                sendMotorCommand(this.state.controlRef, selectedEngine, "ManualControlDown", false);
              }}
              type="primary"
              style={{borderRadius:'10%',width:'100%',marginRight: '25px',height: '60px'}}
              icon={<MinusCircleOutlined />}
              size="large"
            />
            <Button
              onTouchStart={() => {
                sendMotorCommand(
                  this.state.controlRef,
                  selectedEngine,
                  "ManualControlUp",
                  true
                );
              }}
              onTouchEnd={() => {
                sendMotorCommand(
                  this.state.controlRef,
                  selectedEngine,
                  "ManualControlUp",
                  false
                );
              }}
              onMouseDown={() => {
                sendMotorCommand(this.state.controlRef, selectedEngine, "ManualControlUp", true);
              }}
              onMouseUp={() => {
                sendMotorCommand(this.state.controlRef, selectedEngine, "ManualControlUp", false);
              }}
              type="primary"
              
              style={{borderRadius:'10%',width:'100%',height: '60px'}}
              icon={<PlusCircleOutlined />}
              size="large"
              />
          </div>
        </NeuDiv>
      </Col>
    );
  }

  renderButton = (text, onClick, image) => {
    return (
      <Col xs={12} sm={12} md={12} lg={12}>
        <NeuButton width="100%" minHeight={"8vw"} style={{ padding: "5%" }} color={this.divColor} onClick={onClick}>
          <p style={{fontWeight: "bold" }}>{text}</p>
          {image !== undefined ? <img alt="animation_image" style={{ width: "30%" }} src={require("../images/" + image + ".png")} /> : null}
        </NeuButton>
      </Col>
    );
  };

  render() {
    const { isMounted, loadingPercentage } = this.state;
    const { selectedEngine, current_state } = this.props;
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          overflowY: "scroll",
        }}
      >
        <div
          style={{
            marginTop: "50px",
            paddingLeft: "10%",
            paddingRight: "10%",
          }}
        >
          <Row gutter={[24, 24]}>
            <Col span={24}>
              <Row gutter={[16, 16]}>
                {this.renderButton(current_state.Engines[selectedEngine].Powered ? "Power Off" : "Power On", current_state.Engines[selectedEngine].Powered ? () => powerOffAxis(selectedEngine) : () => powerOnAxis(selectedEngine), "power_button_icon")}
                {this.renderButton("Reset", () => ResetErrorAxis(selectedEngine), "reset_error_icon")}
                {this.renderButton("Calibrate", () => CalibrateAxis(selectedEngine), "calibrate_icon")}
                {this.renderButton("Set Position", () => SetZeroAxis(selectedEngine), "setzero_icon")}
                {this.renderButton(current_state.Engines[selectedEngine].EnExactPosition ? "Disable Exact Position" : "Enable Exact Position", current_state.Engines[selectedEngine].EnExactPosition ? () => DisableExactPositionAxis(selectedEngine) : () => EnableExactPositionAxis(selectedEngine), "exact_position_icon")}
                {this.renderEngines()}

              </Row>
            </Col>
          </Row>
        </div>
        {/*isMounted && (
          <SimulatorAnimation
            onProgress={(loadingPercentage) =>
              this.setState({ loadingPercentage })
            }
          />
        )}
        {isMounted && loadingPercentage !== 100 && (
          <div
            style={{
              position: "absolute",
              left: "50%",
              top: "15%",
              transform: "translate(-50%,-50%)",
            }}
          >
            <p style={{ fontFamily: "Avenir", fontWeight: "bold" }}>
              Model Yükleniyor {loadingPercentage}%
            </p>
          </div>
          )*/}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  tcp_state: state.sensors.tcp_connected,
  current_state: state.sensors.current_state,
});

export default connect(mapStateToProps)(Container);
