import React, { Component } from "react";
import { connect } from "react-redux";
import { GetSettings, SettingsButtonPressed, SettingsSaved } from "../Redux/Actions/ApiActions";
import { Input, Button, Switch } from "antd";
import { toggleDarkMode } from "../Redux/Actions/AppActions";

class Settings extends Component {
  state = {
    Settings: {},
  };
  componentDidMount() {
    this.props.dispatch(GetSettings());
  }

  buttonClicked(obj, key) {
    obj.value = true;
    this.props.dispatch(SettingsButtonPressed(obj, key));
  }

  renderSettings() {
    try {
      return Object.keys(this.props.settings.Settings).map((mainkey) => {
        let obj: Object = this.props.settings.Settings[mainkey];
        if (obj.hasOwnProperty("type")) {
          switch (obj.type) {
            case "button":
              return (
                <Button style={{ margin: "5px" }} onClick={() => this.buttonClicked(obj, mainkey)} key={"key" + obj.label}>
                  {obj.label}
                </Button>
              );
            case "number":
              return (
                <Input
                  style={{ margin: "5px" }}
                  type="number"
                  key={"key" + obj.label}
                  placeholder={obj.value}
                  addonBefore={obj.label}
                  onChange={(event) => {
                    obj = parseInt(event.target.value);
                    this.setState({
                      Settings: { [mainkey]: obj },
                    });
                  }}
                ></Input>
              );
            case "text":
              return (
                <Input
                  onChange={(event) => {
                    obj = event.target.value;
                    this.setState({
                      Settings: { [mainkey]: obj },
                    });
                  }}
                  style={{ margin: "5px" }}
                  key={"key" + obj.label}
                  placeholder={obj.value}
                  addonBefore={obj.label}
                ></Input>
              );
            default:
              return null;
          }
        } else {
          return Object.keys(obj).map((e) => {
            let subObj: Object = obj[e];
            switch (subObj.type) {
              case "button":
                return <Button key={"key" + subObj.label}>{subObj.label}</Button>;
              case "number":
                return (
                  <Input
                    type="number"
                    key={"key" + subObj.label}
                    onChange={(event) => {
                      if (event.target.value !== "") {
                        subObj = parseInt(event.target.value);
                      } else {
                        subObj = null;
                      }
                      let state = this.state.Settings;

                      try {
                        state[mainkey] = { ...state[mainkey], [e]: subObj };
                      } catch (error) {}
                      this.setState({
                        Settings: state,
                      });
                    }}
                    placeholder={subObj.value}
                    addonBefore={subObj.label}
                  ></Input>
                );
              case "text":
                return (
                  <Input
                    onChange={(event) => {
                      if (event.target.value !== "") {
                        subObj = event.target.value;
                      } else {
                        subObj = null;
                      }
                      let state = this.state.Settings;
                      try {
                        state[mainkey] = { ...state[mainkey], [e]: subObj };
                      } catch (error) {}
                      this.setState({
                        Settings: state,
                      });
                    }}
                    key={"key" + subObj.label}
                    placeholder={subObj.value}
                    addonBefore={subObj.label}
                  ></Input>
                );
              default:
                return null;
            }
          });
        }
      });
    } catch (error) {}
  }

  render() {
    return (
      <div style={{ width: "100%", height: "100%", padding: "5%" }}>
        {this.props.TwincatWrongIp ? <p style={{ color: "red" }}>Unable to Connect Twincat, Please Check Your Configuration</p> : null}
        <div style={{ width: "50%" }}>{this.renderSettings()}</div>
        <Switch
          style={{ position: "absolute", right: "5%", top: "20px" }}
          checkedChildren="Light"
          unCheckedChildren="Dark"
          onChange={(checked) => {
            this.props.dispatch(toggleDarkMode());
          }}
          checked={this.props.theme.isDark}
        />

        <Button
          onClick={() => {
            SettingsSaved({ Settings: this.state.Settings });
          }}
          /*style={{ position: "absolute", right: "2%", bottom: "2%" }}*/
        >
          Save
        </Button>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  settings: state.settings.settings,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
