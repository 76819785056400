import React, { Component } from "react";
import { Table, Space, Button, Input, Tag } from "antd";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { getLogs } from "../Redux/Actions/ApiActions";
import "antd/dist/antd-with-locales.min";
import { hideLoading, showLoading } from "../Common/Loading";
import { NeuButton } from "neumorphism-react";
import { ResetError } from "../Redux/Actions/ApiActions";

import * as dayjs from 'dayjs'
import * as isLeapYear from 'dayjs/plugin/isLeapYear' // import plugin
import 'dayjs/locale/en' // import locale

dayjs.extend(isLeapYear) // use plugin
dayjs.locale('en') // use locale


class LogsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: "",
      searchedColumn: "",
    };
  }
  componentDidMount() {
    if (this.props.theme.isDark) {
      require("antd/dist/antd-with-locales.min");
    } else {
      require("antd/dist/antd-with-locales.min");
    }
    if (this.props.logs.length < 10) {
      showLoading(null, null, 40).then(async (ref) => {
        this.props.dispatch(
          getLogs(() => {
            hideLoading(ref);
          })
        );
      });
    }
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            width: 188,
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : "",
      onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  render() {
    const columns = [
      {
        title: "Message",
        dataIndex: "Message",
        width: "25%",
        responsive: ['lg'],
        ...this.getColumnSearchProps("Message"),
        render: (table, row) => {
          try {
            return JSON.parse(row.message).Message;
          } catch {
            return row.message;
          }
        },
      },
      {
        title: "Date",
        dataIndex: "timeGenerated",
        width: "40%",
        responsive: ['lg'],
        sorter: {
          compare: (a, b) => dayjs(b.timeGenerated).unix() - dayjs(a.timeGenerated).unix(),
          multiple: 2,
        },
      },

      {
        title: "Code",
        dataIndex: "errorCode",
        width: "10%",
        responsive: ['lg'],
        sorter: {
          compare: (a, b) => a.errorCode.length - b.errorCode.length,
          multiple: 2,
        },
      },
      {
        title: "Type",
        dataIndex: "type",
        width: "10%",
        responsive: ['lg'],
        sorter: {
          compare: (a, b) => a.type.length - b.type.length,
          multiple: 1,
        },
        render: (table, row) => {
          let color = row.type === 0 ? "red" : row.type === 1 ? "yellow" : "green";

          return (
            <Tag color={color} key={row.TimeGenerated + Math.random()}>
              {row.type ===0 ? "Error" : "Warning"}
            </Tag>
          );
        },
      },
      {
        title: "Reset",
        width: "10%",
        responsive: ['lg'],
        render: (table, row) => {
          return (
            <Button
              //icon={<ClearOutlined/>}
              danger={row.entryType === "Error"}
              onClick={() => { 
                ResetError() 
                }}>
              Try Reset
            </Button>
          );
        },
      },
    ];

    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          overflow: "auto",
          padding: "2%",
        }}>
        <Table
          tableLayout="auto"
          size="middle"
          style={{
            height: "100%",

          }}
          columns={columns}
          rowKey={(record) => "" + record.TimeGenerated + Math.random()}
          dataSource={this.props.logs}
          pagination={{ position: ["bottomCenter"] }}
        />
      </div>

    );
  }
}

const mapStateToProps = (state) => ({
  logs: state.sensors.logs,
});

export default connect(mapStateToProps)(LogsTable);
