import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Spring } from "react-spring/renderprops";
let portalContainer = document.getElementById("modal");

export function renderTwincatModal() {
  ReactDOM.render(<PopUp></PopUp>, portalContainer);
}

class PopUp extends Component {
  state = {
    toggle: true,
  };

  onCancel() {
    this.setState({ toggle: false });
    setTimeout(() => {
      ReactDOM.render(null, portalContainer);
    }, 700);
  }

  render() {
    return (
      <Spring
        from={{
          opacity: this.state.toggle ? 0 : 1,
        }}
        to={{
          opacity: this.state.toggle ? 1 : 0,
        }}
        config={{ duration: 500 }}
      >
        {(props) => <div style={{ ...props, ...style.mainContainerStyle }} onClick={this.onCancel.bind(this)}></div>}
      </Spring>
    );
  }
}

const style = {
  text: {
    color: "rgb(230, 230, 230)",
    fontFamily: "Montserrat",
    fontSize: "2vw",
  },
  mainContainerStyle: {
    backdropFilter: "blur(10px)",
    position: "absolute",
    left: 0,
    top: 0,
    zIndex: 1,
    backgroundColor: "rgba(39, 42, 47, 0.8)",
    width: "100vw",
    height: "100vh",
  },
  containerStyle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
    zIndex: 10,
    width: "50vw",
    height: "30vw",
  },
};
