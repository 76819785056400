import React, { Component, createRef } from "react";
import { animated } from "react-spring";
import { NeuButton, NeuDiv } from "neumorphism-react";
import { Spring } from "react-spring/renderprops";
import { myMap } from "../constants";

const SEAT_LOWER_ANGLE = -30;
const SEAT_HIGHER_ANGLE = 50;
const LEFT_JOYSTICK_LOWER_ANGLE = -10;
const LEFT_JOYSTICK_HIGHER_ANGLE = 10;

export default class AdjustSeat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      leftJoystickPosition: 0,
      seatPosition: 0,
      toggle: false,
      leftJoystickToggle: false,
    };
    this.myref = createRef();
  }

  seatInterval;
  leftJoystickInterval;

  render() {
    return (
      <div
        key="seatadjustcontainer"
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {this.props.showHeader ? <p style={{ ...styles.p1, ...this.props }}>Let's Adjust Your Seat</p> : null}
        <Spring
          from={{
            transform: `translatex(0px) scale(1)`,
            number: 80,
            myOpacity: this.state.toggle ? 0.6 : 1,
            myScale: this.state.toggle ? "scale(1)" : "scale(1.5)",
            leftJoystickTranform: `translatex(0px) scale(1)`,
          }}
          to={{
            transform: `translateX(${0.5 * this.state.seatPosition}px scale(${myMap(this.state.seatPosition, -50, 80, 0.7, 1)}))`,
            number: 80 + this.state.seatPosition,
            myOpacity: this.state.toggle ? 1 : 0.6,
            myScale: this.state.toggle ? "scale(1.5)" : "scale(1)",
            leftJoystickTranform: `translateX(${0.5 * this.state.leftJoystickPosition + 0.5 * this.state.seatPosition}px scale(${myMap(this.state.leftJoystickPosition, -10, +10, 0.1, 0.2) + myMap(this.state.seatPosition, -50, 80, 0.5, 0.8)}))`,
          }}
        >
          {(props) => (
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
              }}
            >
              <img
                alt="background"
                style={{
                  position: "absolute",
                  left: 0,
                  bottom: 0,
                  zIndex: 0,
                  width: "100%",
                  height: "100%",
                  objectFit: "fill",
                  objectPosition: "center bottom",
                }}
                src={this.props.theme.isDark ? require("../images/background.png") : require("../images/background_w.png")}
              />
              <div
                style={{
                  padding: "1vw",
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <NeuDiv
                  revert
                  color={this.props.theme.MAIN_BACKGROUND_COLOR}
                  style={{
                    padding: "1%",
                    width: "50%",
                    height: "70%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "space-around",
                      }}
                    >
                      <NeuButton
                        color={this.props.theme.MAIN_BACKGROUND_COLOR}
                        style={{
                          ...styles.adjustButton,
                        }}
                        onMouseDown={() => {
                          this.leftJoystickInterval = setInterval(() => {
                            if (this.state.leftJoystickPosition < LEFT_JOYSTICK_HIGHER_ANGLE) {
                              this.setState({
                                leftJoystickToggle: true,
                                leftJoystickPosition: this.state.leftJoystickPosition + 2,
                              });
                            } else {
                              clearInterval(this.leftJoystickInterval);
                            }
                          }, 200);
                        }}
                        onMouseUp={() => {
                          this.setState({
                            leftJoystickToggle: false,
                          });

                          clearInterval(this.leftJoystickInterval);
                        }}
                        onTouchStart={() => {
                          this.leftJoystickInterval = setInterval(() => {
                            if (this.state.leftJoystickPosition < LEFT_JOYSTICK_HIGHER_ANGLE) {
                              this.setState({
                                leftJoystickToggle: true,
                                leftJoystickPosition: this.state.leftJoystickPosition + 2,
                              });
                            } else {
                              clearInterval(this.leftJoystickInterval);
                            }
                          }, 200);
                        }}
                        onTouchEnd={() => {
                          this.setState({
                            leftJoystickToggle: false,
                          });

                          clearInterval(this.leftJoystickInterval);
                        }}
                      >
                        <img alt="left_joystick_increase" style={{ width: "80%" }} src={require("../images/left_joystick_increase.png")} />
                      </NeuButton>
                      <NeuButton
                        color={this.props.theme.MAIN_BACKGROUND_COLOR}
                        style={styles.adjustButton}
                        onMouseDown={() => {
                          this.leftJoystickInterval = setInterval(() => {
                            if (this.state.leftJoystickPosition > LEFT_JOYSTICK_LOWER_ANGLE) {
                              this.setState({
                                leftJoystickToggle: true,
                                leftJoystickPosition: this.state.leftJoystickPosition - 2,
                              });
                            } else {
                              clearInterval(this.leftJoystickInterval);
                            }
                          }, 200);
                        }}
                        onMouseUp={() => {
                          this.setState({
                            leftJoystickToggle: false,
                          });
                          clearInterval(this.leftJoystickInterval);
                        }}
                        onTouchStart={() => {
                          this.leftJoystickInterval = setInterval(() => {
                            if (this.state.leftJoystickPosition > LEFT_JOYSTICK_LOWER_ANGLE) {
                              this.setState({
                                leftJoystickToggle: true,
                                leftJoystickPosition: this.state.leftJoystickPosition - 2,
                              });
                            } else {
                              clearInterval(this.leftJoystickInterval);
                            }
                          }, 200);
                        }}
                        onTouchEnd={() => {
                          this.setState({
                            leftJoystickToggle: false,
                          });
                          clearInterval(this.leftJoystickInterval);
                        }}
                      >
                        <img alt="left_joystick_decrease" style={{ width: "80%" }} src={require("../images/left_joystick_decrease.png")} />
                      </NeuButton>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "space-around",
                      }}
                    >
                      <NeuButton
                        color={this.props.theme.MAIN_BACKGROUND_COLOR}
                        style={styles.adjustButton}
                        onMouseDown={() => {
                          this.seatInterval = setInterval(() => {
                            if (this.state.seatPosition > SEAT_LOWER_ANGLE) {
                              this.setState({
                                toggle: true,
                                seatPosition: this.state.seatPosition - 5,
                              });
                            } else {
                              clearInterval(this.seatInterval);
                            }
                          }, 200);
                        }}
                        onMouseUp={() => {
                          this.setState({ toggle: false });

                          clearInterval(this.seatInterval);
                        }}
                        onTouchStart={() => {
                          this.seatInterval = setInterval(() => {
                            if (this.state.seatPosition > SEAT_LOWER_ANGLE) {
                              this.setState({
                                toggle: true,
                                seatPosition: this.state.seatPosition - 5,
                              });
                            } else {
                              clearInterval(this.seatInterval);
                            }
                          }, 200);
                        }}
                        onTouchEnd={() => {
                          this.setState({ toggle: false });

                          clearInterval(this.seatInterval);
                        }}
                      >
                        {" "}
                        <img alt="carseat_increase" style={{ width: "80%" }} src={require("../images/carseat_increase.png")} />
                      </NeuButton>
                      <NeuButton
                        color={this.props.theme.MAIN_BACKGROUND_COLOR}
                        style={styles.adjustButton}
                        onMouseDown={() => {
                          this.seatInterval = setInterval(() => {
                            if (this.state.seatPosition < SEAT_HIGHER_ANGLE) {
                              this.setState({
                                toggle: true,
                                seatPosition: this.state.seatPosition + 5,
                              });
                            } else {
                              clearInterval(this.seatInterval);
                            }
                          }, 200);
                        }}
                        onMouseUp={() => {
                          this.setState({ toggle: false });

                          clearInterval(this.seatInterval);
                        }}
                        onTouchStart={() => {
                          this.seatInterval = setInterval(() => {
                            if (this.state.seatPosition < SEAT_HIGHER_ANGLE) {
                              this.setState({
                                toggle: true,
                                seatPosition: this.state.seatPosition + 5,
                              });
                            } else {
                              clearInterval(this.seatInterval);
                            }
                          }, 200);
                        }}
                        onTouchEnd={() => {
                          this.setState({ toggle: false });
                          clearInterval(this.seatInterval);
                        }}
                      >
                        {" "}
                        <img alt="carseat_decrease" style={{ width: "80%" }} src={require("../images/carseat_decrease.png")} />
                      </NeuButton>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "space-around",
                      }}
                    >
                      <NeuButton
                        color={this.props.theme.MAIN_BACKGROUND_COLOR}
                        style={styles.adjustButton}
                        onMouseDown={() => { }}
                        onMouseUp={() => { }}>
                        <img alt="right_joystick_increase" style={{ width: "80%" }} src={require("../images/right_joystick_increase.png")} />
                      </NeuButton>
                      <NeuButton
                        color={this.props.theme.MAIN_BACKGROUND_COLOR}
                        style={styles.adjustButton}
                        onMouseDown={() => { }}
                        onMouseUp={() => { }}>
                        <img alt="right_joystick_decrease" style={{ width: "80%" }} src={require("../images/right_joystick_decrease.png")} />
                      </NeuButton>
                    </div>
                  </div>
                </NeuDiv>
                <div
                  style={{
                    position: "relative",
                    display: "flex",
                    width: "100%",
                    alignItems: "center",
                    height: "100%",
                    justifyContent: "center",
                  }}
                >
                  <animated.img
                    style={{
                      ...props,
                      transform: props.leftJoystickTranform,
                      position: "absolute",
                      zIndex: 3,
                      height: "100%",
                      objectFit: "contain",
                    }}
                    src={require("../images/left_joystick.png")}
                  />
                  <animated.img
                    style={{
                      ...props,
                      position: "absolute",
                      zIndex: 2,
                      height: "100%",
                      objectFit: "contain",
                    }}
                    src={require("../images/carseat.png")}
                  />

                  <animated.p
                    style={{
                      position: "absolute",
                      left: "50%",
                      bottom: 0,
                      margin: 0,
                      zIndex: 3,
                      opacity: props.myOpacity,
                      transform: "translate(-50%, 0) " + props.myScale,
                      fontFamily: "Montserrat",
                      fontSize: "4vw",
                      color: this.props.theme.MAIN_TEXT_COLOR,
                    }}
                  >
                    {parseFloat(props.number).toPrecision(3)}kg
                  </animated.p>
                </div>
              </div>
            </div>
          )}
        </Spring>
      </div>
    );
  }
}

const styles = {
  p1: {
    position: "absolute",
    top: 0,
    zIndex: 5,
    color: "rgb(230,230,230)",
    fontFamily: "Montserrat",
    fontSize: "4vw",
  },

  neudiv: {
    paddingTop: "5%",
    paddingBottom: "5%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  adjustText: {
    margin: 0,
    color: "rgb(230,230,230)",
    fontSize: "1.5vw",
    zIndex: 5,
    textAlign: "center",
  },

  adjustButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Montserrat",
    margin: "5px",
    width: "8vw",
    height: "8vw",
    fontSize: "5vw",
    color: "rgb(230,230,230)",
  },
};
