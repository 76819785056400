import React, { Component } from "react";
import { hideLoading } from "../Common/Loading";
import { BLUE_BACKGROUND_COLOR, FOOTER_TEXT_COLOR } from "../constants";
import { NeuDiv, NeuButton } from "neumorphism-react";
import { Tabs, Tab, Grid } from "@mui/material";
import { Transition } from "react-spring/renderprops";
import { animated } from "react-spring";
import AdjustSeat from "../Components/firstView_AdjustSeat";
import LogsTable from "../Components/mainScreen_LogsTable";
import MainScreenVertical from "../Components/mainScreen_Vertical";
import { connect } from "react-redux";
import { Calibrate, connectApi, ResetError, SetZero, powerOn, powerOff, IntegrationSTARTSTOP, GetIntegrations } from "../Redux/Actions/ApiActions";
import Settings from "../Components/mainScreen_Settings";
import { checkBelts, getGameInfo, isNullorUndefined } from "../Common/Helper";
import Gauge from "../Common/Gauge";
import { renderIntegrationModal } from "../Common/integrationModal";
import { renderSeatbeltModal } from "../Common/seatbeltModal";
import { Layout, notification } from "antd";
import { bws } from "../Components/firstView_SeatBelts";
import { renderPopUp } from "../Common/PopUp";
import { Content } from "antd/es/layout/layout";
export const red = "#a83e3e";
export const green = "#80914a";
export const yellow = "#dbd77d";
export const orange = "#FF8800";
export const gray = "#979797";

class MainScreen extends Component {
  constructor(props) {
    super(props);
    this.onResizeEvent = this.onResizeEvent.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.handleOk = this.handleOk.bind(this);
  }

  state = {
    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight,
    footerButtons: [
      { id: "FOOTER_BUTTON_SENSORS", text: "SENSORS" },
      { id: "FOOTER_BUTTON_STATUS", text: "INPUTS" },
      { id: "FOOTER_BUTTON_LOGS", text: "LOGS" },
      { id: "FOOTER_BUTTON_SETTINGS", text: "SETTINGS" },
      { id: "FOOTER_BUTTON_PLATFORM", text: "PLATFORM" },
    ],
    selectedTabIndex: 0,
    width: "500px",
  };

  styles = {
    commonCardText: {
      color: this.props.theme.MAIN_TEXT_COLOR,
      fontFamily: "Montserrat",
      fontWeight: "bold",
      fontSize: "1vw",
    },
    footerText: {
      //color: MAIN_TEXT_COLOR,
      fontFamily: "Montserrat",
      fontWeight: "bold",
      fontSize: "2vw",
      margin: 0,
    },
    footer: {
      zIndex: 10,
      width: "100%",
      height: "7%",
      backgroundColor: this.props.theme.SECONDARY_BACKGROUND_COLOR,
    },

    leftMenuText: {
      margin: 0,
      fontSize: "1.2vw",
      fontFamily: "Montserrat",
      color: this.props.theme.MAIN_TEXT_COLOR,
      fontWeight: "bold",
    },
    backgroundContainer: {
      display: "flex",
      height: "93%",
      backgroundColor: this.props.theme.MAIN_BACKGROUND_COLOR,
    },
    content: {
      display: "flex",
      height: "100%",
      backgroundColor: this.props.theme.MAIN_BACKGROUND_COLOR,
    },
  };

  handleResize(e) {
    this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight });
  }
  handleOk() {
    this.props.dispatch(GetIntegrations());
  }
  checkMotors() {
    let power = true;
    Object.values(this.props.current_state.Engines).map((value) => {
      if (!value.Power) power = false;
      return true;
    });
    return power;
  }

  componentDidMount() {
    try {
      setTimeout(() => {
        this.onResizeEvent();
      }, 200);

      window.addEventListener("resize", this.onResizeEvent);
      window.addEventListener("resize", this.handleResize);

      if (this.props.location.state.TwincatWrongIp) {
        this.setState({ selectedTabIndex: 4 });
      }
    } catch { }
    setTimeout(() => {
      try {
        hideLoading(this.props.location.loadingRef);
      } catch (error) {
        console.log(error);
      }
    }, 100);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
    window.removeEventListener("resize", this.onResizeEvent);
  }

  onResizeEvent() {
    try {
      let width = document.getElementById("google").offsetWidth;
      if (width) {
        this.setState({ width: width });
      }
    } catch (error) {
      this.setState({ error });
    }
  }

  getErrorId(engine) {
    let errorstr = "";
    let errors = ["PowerErrorId", "ExactPositionErrorId", "ResetErrorStatusId", "AxisErrorId", "StopAxisErrorId", "SetPositionErrorId"];
    errors.map((error) => {
      if (engine[error] !== 0 && engine[error] !== undefined && engine[error] !== null) {
        errorstr = errorstr + engine[error] + " ";
      }
      return true;
    });
    return errorstr;
  }

  renderCommonCard(status, onPress, icon, value, valuetype, isBase64) {
    return (
      <Grid item xs={6} sm={6} md={4} lg={3} style={{ display: "flex" }} onClick={onPress}>
        <NeuDiv
          style={{
            height: this.state.width,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: "column",
          }}
          className="NeuSquare"
          id="google"
          color={this.props.theme.MAIN_BACKGROUND_COLOR}>
          <div
            style={{
              alignSelf: "flex-end",
              margin: "2%",
              backgroundColor: status,
              width: "1vw",
              height: "1vw",
              borderRadius: "200px",
            }}
          />
          {isBase64 ?
            <img alt="covisart__icon" style={{ objectFit: "contain", width: "80%", height: "60%" }} src={icon} /> :
            <img alt="covisart__asdaicon" style={{ objectFit: "contain", width: "80%", height: "60%" }} src={require(`../images/${icon}.png`)} />
          }
          <p
            style={{
              ...this.styles.commonCardText,
              position:"relative",
              bottom:15
            }}>
            {" "}
            {value + valuetype}
          </p>
        </NeuDiv>
      </Grid>
    );
  }

  renderQuaternaryCard() {
    return (
      <Grid item xs={6} sm={6} md={4} lg={3} style={{ display: "flex" }}>
        <NeuDiv
          style={{
            padding: "5%",
            height: this.state.width,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: "column",
          }}
          className="NeuSquare"
          id="google"
          color={this.props.theme.MAIN_BACKGROUND_COLOR}
        >
          <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
            <Grid
              item
              xs={6}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img alt="ac" style={{ width: "70%", objectFit: "contain" }} src={require("../images/ac.png")}></img>
              <p style={this.styles.commonCardText}>380V</p>
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img alt="dc" style={{ width: "70%", objectFit: "contain" }} src={require("../images/dc.png")}></img>
              <p style={this.styles.commonCardText}>24V</p>
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img alt="connected" style={{ width: "70%", objectFit: "contain" }} src={require("../images/connected.png")}></img>
              <p style={this.styles.commonCardText}>{this.props.tcp_state === 2 ? "Connected" : this.props.tcp_state === 0 ? "No Connection" : this.props.tcp_state === 1 ? "Connecting" : "No Connection"}</p>
            </Grid>

            <Grid
              item
              xs={6}
              style={{
                display: "flex",
                cursor: "pointer",
                flexDirection: "column",
                alignItems: "center",
              }}
              onClick={() => {
                if (!this.props.current_state.IsTwinCatConnectionEstablished) {
                  connectApi();
                }
              }}
            >
              <img alt="motor_connected" style={{ width: "70%", objectFit: "contain" }} src={require("../images/motor_connected.png")}></img>
              <p style={this.styles.commonCardText}>{this.props.current_state.IsTwinCatConnectionEstablished ? "Connected" : "No Connection"}</p>
            </Grid>
          </Grid>
        </NeuDiv>
      </Grid>
    );
  }

  renderSensorCard(powerstatus, exactpositionstatus, onPress, value, text, valueType, minValue, maxValue, errorId) {
    return (
      <Grid key={"grid_key" + text} item xs={6} sm={6} md={4} lg={3} style={{ display: "flex" }}>
        <NeuDiv
          style={{
            padding: "2%",
            height: this.state.width,
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            flexDirection: "column",
            position: "relative",
          }}
          className="NeuSquare"
          id="google"
          color={this.props.theme.MAIN_BACKGROUND_COLOR}
        >
          <div
            style={{
              alignSelf: "flex-end",
              margin: "2%",
              backgroundColor: powerstatus,
              width: "1vw",
              height: "1vw",
              borderRadius: "5000px",
              color: "rgba(0,0,0,0)",
            }}
          ></div>
          <div
            style={{
              alignSelf: "flex-end",
              margin: "2%",
              backgroundColor: exactpositionstatus,
              width: "1vw",
              height: "1vw",
              borderRadius: "5000px",
              color: "rgba(0,0,0,0)",
            }}
          ></div>

          <Gauge value={isNullorUndefined(value) || isNaN(value) ? 0 : value} valueType={valueType}></Gauge>

          <p
            style={{
              position: "absolute",
              left: "0",
              width: "100%",
              textAlign: "center",
              top: 0,
              transform: "translateX: -50%",
            }}
          >
            {errorId ? "Error: " + errorId : null}
          </p>

          <p
            style={{
              ...this.styles.commonCardText,
              position: "absolute",
              bottom: 15,
            }}
          >
            {" "}
            {text}
          </p>
        </NeuDiv>
      </Grid>
    );
  }

  renderSensorIcons() {
    let { Engines } = this.props.current_state;
    let gameInfo = getGameInfo();
    let VrDevice = this.props.current_state.VrDevice;
    return (
      <Grid container spacing={2}
        style={{
          padding:"2%",
        }}>
        {this.props.current_state
          ? Object.keys(Engines).map((key) => {
            return this.renderSensorCard(
              Engines[key].Powered ? green : red,                 //powerstatus
              Engines[key].EnExactPosition ? orange : gray,       //exactpositionstatus
              null,                                               //onPress
              parseFloat(Engines[key].CurrentPosition).toFixed(), //value
              Engines[key].Name,                                  //text
              "°",                                                //valueType
              0,                                                  //minValue
              360,                                                //maxValue
              this.getErrorId(Engines[key])                       //errorId
            );
          })
          : null}
        {
          //this.renderQuaternaryCard()
        }

        {this.renderCommonCard(red, null, "temperature_icon", "32", "°")}
        {this.renderCommonCard(VrDevice.connection ? green : red, null, "vr", VrDevice.connection ? "Connected" : "Disconnected", "")}
        {this.renderCommonCard(this.props.theme.MAIN_BACKGROUND_COLOR, null, "LOGO", "", "")}
        {this.renderCommonCard(this.props.theme.MAIN_BACKGROUND_COLOR, null, "covisart", "", "")}
        {/*this.renderCommonCard(blue, null, "tunguska", "", "")*/}
        {!isNullorUndefined(this.props.current_state.Integration)
          ? this.renderCommonCard(
            this.props.current_state.Integration.State ? green : red,
            () => {
              console.log("hello")
            },
            this.props.current_state.Integration.Base64Image,
            this.props.current_state.Integration.Name,
            "",
            true
          )
          : this.renderCommonCard(
            gameInfo.state ? green : red,
            () => {
              renderIntegrationModal(this.props.integrations, this.props.theme);
            },
            gameInfo.base64Image,
            gameInfo.name,
            "",
            true
          )
        }
      </Grid>
    );
  }

  renderIntegrationIcons() {
    let Axis = this.props.current_state?.Integration ? (Object.keys(this.props.current_state.Integration.Axis).length !== 0 ? this.props.current_state.Integration.Axis : { Pitch: 0, Yaw: 0, Roll: 0 }) : { Pitch: 0, Yaw: 0, Roll: 0 };
    let gameInfo = getGameInfo();
    let Integration = this.props.current_state.Integration;
    return (
      <Grid container spacing={7} style={{ padding: "2%" }} >
        {!isNullorUndefined(Integration)
          ? this.renderCommonCard(
            Integration.State ? green : red,
            () => {
              renderIntegrationModal(this.props.integrations, this.props.theme);
            },
            Integration.Base64Image,
            Integration.Name,
            "",
            true
          )
          : this.renderCommonCard(
            gameInfo.state ? green : red,
            () => {
              renderIntegrationModal(this.props.integrations, this.props.theme);
            },
            gameInfo.base64Image,
            gameInfo.name,
            "",
            true
          )}

        {Object.keys(Axis).map((key) => {
          return this.renderSensorCard(green, null, null, parseInt(Axis[key]), key, "°", 0, 360, null);
        })}
      </Grid>
    );
  }

  renderSeatAdjust() {
    return <AdjustSeat theme={this.props.theme} />;
  }

  renderSettings() {
    return <Settings theme={this.props.theme} TwincatWrongIp={this.props?.location?.state?.TwincatWrongIp} />;
  }

  renderBookshelf(index) {
    return (
      <NeuDiv
        revert
        style={{
          justifySelf: "center",
          width: "95%",
          height: "100%",
          overflowY: "visible",
        }}
        color={this.props.theme.MAIN_BACKGROUND_COLOR}>
        {
          index === 0
            ? this.renderSensorIcons()
            : index === 1
              ? this.renderIntegrationIcons()
              : index === 2
                ? <LogsTable selam={""} theme={this.props.theme} />
                : index === 3
                  ? this.renderSeatAdjust()
                  : this.renderSettings()
        }
      </NeuDiv>
    );
  }

  LeftMenuButton(onClick, text, icon) {
    return (
      <NeuButton
        className="leftMenuButton"
        onClick={onClick}
        color={this.props.theme.MAIN_BACKGROUND_COLOR}
        style={{
          margin: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: "row",
          width: "70%",
          height: "auto",
          padding: "5%",
          borderRadius: "20px",
        }}
      >
        <img alt="covisart_icon" style={{ objectFit: "contain", width: "40%" }} src={require(`../images/${icon}.png`)} />
        <p style={this.styles.leftMenuText}>{text}</p>
      </NeuButton>
    );
  }

  renderLeftButtons() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          justifyContent: "center",
        }}
      >
        <NeuButton
          className="leftMenuButton"
          onClick={() =>
          (
            renderPopUp(this, this.checkMotors()
              ? "Device will be Powered Off?"
              : "Device will be Powered On?",
              "Confirm",
              () => { this.checkMotors() ? powerOff() : powerOn() },
              this.props.theme)
          )
          }
          color={this.props.theme.MAIN_BACKGROUND_COLOR}
          style={{
            width: "40%",
            height: "auto",
            borderRadius: "200px",
            marginBottom: "0",
            padding: "10%",
          }}
        >
          <img alt="power_icon" style={{ objectFit: "contain", width: "100%", height: "100%" }} src={require(this.checkMotors() ? "../images/power_icon.png" : "../images/unpower_icon.png")} />
        </NeuButton>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "70%",
            alignItems: "center",
            justifyContent: "space-evenly",
          }}
        >
          {this.LeftMenuButton(() =>
          (this.checkMotors() ?
            renderPopUp(this, isNullorUndefined(this.props.current_state.Integration) ?
              "Enable motion control system" : "Disable motion control system", "Confirm", () => {
                let key;
                let state = isNullorUndefined(this.props.current_state.Integration) ? "START" : "STOP";
                if (this.props.selectedGame) {
                  key = this.props.selectedGame;
                } else {
                  key = localStorage.getItem("selectedGame");
                }
                if (key) {
                  if (state === "STOP") {
                    IntegrationSTARTSTOP(key, state, this.props.seatBeltStatus, this.props.theme);
                  } else {
                    if (checkBelts(this.props.seatBeltStatus)) {
                      IntegrationSTARTSTOP(key, state, this.props.seatBeltStatus, this.props.theme);
                    } else {
                      renderSeatbeltModal(this.props.seatBeltStatus, this.props.theme, () => {
                        try {
                          bws.close();
                        } catch { }
                      });
                    }
                  }
                } else {
                  notification.warning({
                    message: "Please select a game.",
                    placement: "topRight",
                  });
                }
              }, this.props.theme)
            : notification.error({
              message: "Please power on first..",
              placement: "topRight",
            })),
            isNullorUndefined(this.props.current_state.Integration) ? "START" : "STOP",
            isNullorUndefined(this.props.current_state.Integration) ? "stop_icon" : "start_icon")}
          {this.LeftMenuButton(() => renderPopUp(this, "Device errors will be reset?", "Confirm", ResetError, this.props.theme), "Reset Error", "reset_error_icon")}
          {this.LeftMenuButton(() => renderPopUp(this, "Device will be calibrated?", "Confirm", Calibrate, this.props.theme), "Calibrate", "calibrate_icon")}
          {this.LeftMenuButton(() => renderPopUp(this, "This position will be set to 0", "Confirm", SetZero, this.props.theme), "Position", "setzero_icon")}
        </div>
      </div>
    );
  }

  renderFooterButtons() {
    return this.state.footerButtons.map((button) => {
      return (
        <Tab
          style={{
            display: "flex",
            flex: 1,
            maxWidth: "100%",
            fontSize: "1vw",
            fontFamily: "Montserrat",
            fontWeight: "bold",
            color: FOOTER_TEXT_COLOR,
          }}
          key={button.id}
          label={button.text}
        />
      );
    });
  }

  renderFooter() {
    return (
      <div style={{ display: "flex", flexDirection: "row", height: "100%" }}>
        <Tabs
          onChange={(e, newValue) => {
            this.setState({ selectedTabIndex: newValue });
          }}
          style={{
            display: "flex",
            justifyContent: "space-around",
            backgroundColor: BLUE_BACKGROUND_COLOR,
            width: "100%",
            height: "100%",
          }}
          value={this.state.selectedTabIndex}
        >
          {this.renderFooterButtons()}
        </Tabs>
      </div>
    );
  }

  render() {
    const { selectedTabIndex, windowWidth, windowHeight } = this.state;
    const isVertical = windowWidth < windowHeight;
    return isVertical ? (
      <MainScreenVertical theme={this.props.theme} />
    ) : (
      <Layout style={{ height: "100vh" }}>
        <Content style={this.styles.content}>
          <div
            style={{
              width: "20%",
              minWidth: "100px",
              height: "100%",
              display: "flex",
            }}>
            {this.renderLeftButtons()}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "80%",
              alignItems: "center",
            }}>
            <header>
              <img
                alt="covisart_logo"
                style={{
                  width: "20vw",
                  margin: "10%",
                  objectFit: "contain",
                }}
                src={require("../images/NGS-360.png")}
              />
            </header>
            <Transition
              initial={null}
              config={{ duration: 700 }}
              reset
              unique
              items={selectedTabIndex}
              from={{
                transform: "translate3d(100vw, 0 ,0)",
              }}
              enter={{
                opacity: 1,
                transform: "translate3d(0, 0, 0)",
              }}
              leave={{
                opacity: -5,
              }}>
              {(index) => (style) =>
              (
                <animated.div
                  style={{
                    ...style,
                    position: "absolute",
                    top: "10%",
                    width: "80%",
                    height: "80%",
                    zIndex: 1,
                  }}
                >
                  {this.renderBookshelf(index)}
                </animated.div>
              )}
            </Transition>
          </div>
        </Content>
        <footer style={this.styles.footer}>{this.renderFooter()}</footer>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => ({
  seatBeltStatus: state.sensors.current_state.SeatBelt,
  integrations: state.sensors.integrations,
  tcp_state: state.sensors.tcp_connected,
  current_state: state.sensors.current_state,
});

export default connect(mapStateToProps)(MainScreen);
