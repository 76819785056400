export const MODE =
  localStorage.getItem("mode") !== null ? localStorage.getItem("mode") : "dark";
export const SCREEN_WIDTH = window.screen.availWidth;
export const SCREEN_HEIGHT = window.screen.availHeight;
export const MAIN_TEXT_COLOR =
  MODE === "dark" ? "rgb(230,230,230)" : "rgb(30,30,30)";
export const FOOTER_TEXT_COLOR =
  MODE === "dark" ? "rgb(220,220,220)" : "rgb(230,230,230)";
export const MAIN_BACKGROUND_COLOR = MODE === "dark" ? "#272A2F" : "#c5c5c5";
export const SECONDARY_BACKGROUND_COLOR =
  MODE === "dark" ? "#2C2F35" : "#525252";
export const BLUE_BACKGROUND_COLOR = "#455A64";
export const ORANGE_COLOR = "#FF8800";

export const myMap = (x, in_min, in_max, out_min, out_max) => {
  return ((x - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min;
};

export const lightTheme = {
  MAIN_TEXT_COLOR: "rgb(30,30,30)",
  FOOTER_TEXT_COLOR: "rgb(230,230,230)",
  MAIN_BACKGROUND_COLOR: "#c5c5c5",
  SECONDARY_BACKGROUND_COLOR: "#525252",
  BLUE_BACKGROUND_COLOR: "#455A64",
  ORANGE_COLOR: "#FF8800",
  isDark: false,
};

export const darkTheme = {
  MAIN_TEXT_COLOR: "rgb(230,230,230)",
  FOOTER_TEXT_COLOR: "rgb(220,220,220)",
  MAIN_BACKGROUND_COLOR: "#272A2F",
  SECONDARY_BACKGROUND_COLOR: "#2C2F35",
  BLUE_BACKGROUND_COLOR: "#455A64",
  ORANGE_COLOR: "#FF8800",
  isDark: true,
};
