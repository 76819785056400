import React, { Component } from "react";
import { createRoot } from 'react-dom/client';
import { Spring } from "react-spring/renderprops";
import PlatformSelect from "../Components/firstView_PlatformSelect";

let portalContainer =  createRoot(document.getElementById("modal"));

export function renderIntegrationModal(integrations, theme) {
  portalContainer.render(<PopUp integrations={integrations} theme={theme}></PopUp>);
}

class PopUp extends Component {
  state = {
    toggle: true,
  };

  onCancel() {
    this.setState({ toggle: false });
    setTimeout(() => {
      portalContainer.unmount();
    }, 700);
  }

  render() {
    return (
      <Spring
        from={{
          opacity: this.state.toggle ? 0 : 1,
        }}
        to={{
          opacity: this.state.toggle ? 1 : 0,
        }}
        config={{ duration: 500 }}
      >
        {(props) => (
          <div style={{ ...props, ...style.mainContainerStyle }} onClick={this.onCancel.bind(this)}>
            <div
              style={style.containerStyle}
              onClick={(event) => {
                event.stopPropagation();
              }}
            >
              <PlatformSelect theme={this.props.theme} integrations={this.props.integrations}></PlatformSelect>
            </div>
          </div>
        )}
      </Spring>
    );
  }
}

const style = {
  text: {
    color: "rgb(230, 230, 230)",
    fontFamily: "Montserrat",
    fontSize: "2vw",
  },
  mainContainerStyle: {
    backdropFilter: "blur(10px)",
    position: "absolute",
    left: 0,
    top: 0,
    zIndex: 1,
    backgroundColor: "rgba(39, 42, 47, 0.8)",
    width: "100vw",
    height: "100vh",
  },
  containerStyle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%,-50%)",
    zIndex: 10,
    width: "70vw",
    height: "min-content",
  },
};
