import { Col, notification, Row,Layout } from "antd";
import { NeuButton, NeuDiv } from "neumorphism-react";
import React, { Component } from "react";
import { connect } from "react-redux";
import Gauge from "../Common/Gauge";
import { checkBelts, isNullorUndefined } from "../Common/Helper";
import { renderPopUp } from "../Common/PopUp";
import { renderSeatbeltModal } from "../Common/seatbeltModal";
import { Calibrate, IntegrationSTARTSTOP, powerOff, powerOn, ResetError, SetZero } from "../Redux/Actions/ApiActions";
import { green, red, orange, gray } from "../Screens/mainScreen";
import { bws } from "./firstView_SeatBelts";

class MainScreenVertical extends Component {
  constructor(props) {
    super(props);
    this.state = { width: 0 };
  }

  styles = {
    leftMenuText: {
      margin: 0,
      fontSize: "3.5vw",
      fontFamily: "Montserrat",
      color: this.props.theme.MAIN_TEXT_COLOR,
      fontWeight: "bold",
    },
  };

  componentDidMount() {
    try {
      this.onResizeEvent();
      window.addEventListener("resize", this.onResizeEvent.bind(this));
    } catch {
      setTimeout(() => {
        this.onResizeEvent();
      }, 200);
    }
  }

  onResizeEvent() {
    let width = document.getElementById("google")?.offsetWidth;
    if (width) {
      this.setState({ width: (width * 3) / 5 });
    } else {
      throw Error;
    }
  }

  checkMotors() {
    let power = true;
    Object.values(this.props.current_state.Engines).map((value) => {
      if (!value.Power) power = false;
      return true;
    });
    return power;
  }
  controlMotor() {
    this.checkMotors() ? powerOff() : powerOn();
  }
  LeftMenuButton(onClick, text, icon, style, textStyle) {
    return (
      <NeuButton
        className="leftMenuButton"
        onClick={onClick}
        color={this.props.theme.MAIN_BACKGROUND_COLOR}
        style={{
          margin: 0,
          height: this.state.width,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "10%",
          justifyContent: "space-between",
          borderRadius: "20px",
          ...style,
        }}
        id={`google`}
      >
        {icon ? <img alt="covisart_icon" style={{ objectFit: "contain", width: "30%" }} src={require(`../images/${icon}.png`)} /> : null}
        <p style={{ ...this.styles.leftMenuText, ...textStyle }}>{text}</p>
      </NeuButton>
    );
  }

  getErrorId(engine) {
    let errorstr = "";
    let errors = ["PowerErrorId", "ExactPositionErrorId", "ResetErrorStatusId", "AxisErrorId", "StopAxisErrorId", "SetPositionErrorId"];
    errors.map((error) => {
      if (engine[error] !== 0 && engine[error] !== undefined && engine[error] !== null) {
        errorstr = errorstr + engine[error] + " ";
      }
      return true;
    });
    return errorstr;
  }

  renderSensorCard(powerstatus, exactpositionstatus, onPress, value, text, valueType, minValue, maxValue, errorId) {
    return (
      <Col xs={8}>
        <NeuDiv
          style={{
            height: (this.state.width * 4) / 5,
            padding: "4%",
            paddingTop: "74.5%",
            borderRadius: "20px",
            alignItems: "center",
            justifyContent: "space-around",
            flexDirection: "column",
            position: "relative",
          }}
          className="NeuSquare"
          color={this.props.theme.MAIN_BACKGROUND_COLOR}
        >
          <div
            style={{
              position: "absolute",
              alignSelf: "flex-end",
              bottom: '15px',
              right: '15px',
              backgroundColor: powerstatus,
              width: "1vw",
              height: "1vw",
              borderRadius: "5000px",
              color: "rgba(0,0,0,0)",
            }}
          ></div>
          <div
            style={{
              alignSelf: "flex-end",
              margin: "2%",
              backgroundColor: exactpositionstatus,
              width: "1vw",
              height: "1vw",
              borderRadius: "5000px",
              color: "rgba(0,0,0,0)",
            }}
          ></div>

          <Gauge value={isNullorUndefined(value) || isNaN(value) ? 0 : value}></Gauge>

          <p
            style={{
              position: "absolute",
              left: "0",
              width: "100%",
              textAlign: "center",
              top: 0,
              transform: "translateX: -50%",
            }}
          >
            {errorId ? "Error: " + errorId : null}
          </p>

          <p
            style={{
              ...this.styles.commonCardText,
              position: "absolute",
              marginBottom: "2%",
              fontSize: "85%",
              bottom: 0,
              color: this.props.theme.MAIN_TEXT_COLOR,
            }}
          >
            {text}
          </p>
        </NeuDiv>
      </Col>
    );
  }

  renderLeftButtons() {
    return (
      <NeuDiv
        revert
        style={{
          position: "absolute",
          width: "95%",
          height: "95%",
          padding: "4%",
          overflowY: "scroll",
          top: "2.5%",
          left: "2.5%",
        }}
        color={this.props.theme.MAIN_BACKGROUND_COLOR}
      >
        <div>
          <Row gutter={[16, 16]} align="top">
            <Col xs={12}>{this.LeftMenuButton(() => renderPopUp(this, this.checkMotors() ? "Motors will be powered off" : "Motors will be powered on", "Confirm", () => { this.checkMotors() ? powerOff() : powerOn(); }, this.props.theme), "Power", this.checkMotors() ? "power_icon" : "unpower_icon")}</Col>
            <Col xs={12}>{this.LeftMenuButton(() => renderPopUp(this, "Device errors will be reset", "Confirm", ResetError, this.props.theme), "Reset", "reset_error_icon")}</Col>
            <Col xs={12}>{this.LeftMenuButton(() => renderPopUp(this, "Device will be calibrated", "Confirm", Calibrate, this.props.theme), "Calibrate", "calibrate_icon")}</Col>
            <Col xs={12}>{this.LeftMenuButton(() => renderPopUp(this, "This position will be set to 0", "Confirm", SetZero, this.props.theme), "Set Position", "setzero_icon")}</Col>
            <Col xs={24}>{
              this.LeftMenuButton(
                () => renderPopUp(this, isNullorUndefined(this.props.current_state.Integration) ? "Enable motion control system" : "Disable motion control system", "Confirm", () => {
                  let key;
                  let state = isNullorUndefined(this.props.current_state.Integration) ? "START" : "STOP";
                  if (this.props.selectedGame) {
                    key = this.props.selectedGame;
                  } else {
                    key = localStorage.getItem("selectedGame");
                  }
                  if (key) {
                    if (state === "STOP") {
                      IntegrationSTARTSTOP(key, state, this.props.seatBeltStatus, this.props.theme);
                    } else {
                      if (checkBelts(this.props.seatBeltStatus)) {
                        IntegrationSTARTSTOP(key, state, this.props.seatBeltStatus, this.props.theme);
                      } else {
                        renderSeatbeltModal(this.props.seatBeltStatus, this.props.theme, () => {
                          try {
                            bws.close();
                          } catch { }
                        });
                      }
                    }
                  } else {
                    notification.warning({
                      message: "Please select a game.",
                      placement: "topRight",
                    });
                  }
                }, this.props.theme),
                isNullorUndefined(this.props.current_state.Integration) ? "START" : "STOP",
                "start_icon",
                { flexDirection: "row", paddingTop: "10%", alignItems: "center", justifyContent: "space-around" },
                { fontSize: "150%" }
              )}
            </Col>
            {Object.keys(this.props.current_state?.Engines).map((key) => {
              let Engines = this.props.current_state.Engines;
              return this.renderSensorCard(Engines[key].Powered ? green : red, Engines[key].EnExactPosition ? orange : gray, null, parseFloat(Engines[key].CurrentPosition).toFixed(), Engines[key].Name, "°", 0, 360, this.getErrorId(Engines[key]));
            })}
          </Row>
        </div>
      </NeuDiv>
    );
  }

  render() {
    return <Layout style={{ height: "100vh", backgroundColor: this.props.theme.MAIN_BACKGROUND_COLOR }}>{this.renderLeftButtons()}</Layout>;
  }
}

const mapStateToProps = (state) => ({
  tcp_state: state.sensors.tcp_connected,
  current_state: state.sensors.current_state,
});

export default connect(mapStateToProps)(MainScreenVertical);
