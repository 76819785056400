import React, { Component } from "react";
import { NeuDiv } from "neumorphism-react";
import ResizeableImage from "./mainScreen_ResizeableImage";
import { renderPopUp } from "../Common/PopUp";
import { showLoading } from "../Common/Loading";

export default class PlatformSelect extends Component {
  state = {
    seatPosition: 0,
    toggle: false,
  };
  renderPlatforms() {
    let keys = Object.keys(this.props.integrations);
    
    return keys.map((real_key) => {
      /*let fakeData = this.props.integrations[real_key];
      console.log(fakeData.name);*/
      return (
        <ResizeableImage
          onSelect={(name, key) => {
            renderPopUp(
              this,
              " Selected input is " + name.toUpperCase() + ". Is it right?",
              "Yes",
              () => {
                showLoading(null, null, 40).then((ref) => {
                  localStorage.setItem("selectedGame", String(real_key));
                  if (this.props.historyPush) {
                    if (this.props.isConnected) {
                      this.props.historyPush({
                        pathname: "/main",
                        loadingRef: ref,
                        selectedGame: key,
                      });
                    } else {
                      this.props.historyPush({
                        pathname: "/main",
                        loadingRef: ref,
                        selectedGame: key,
                        state: { TwincatWrongIp: true },
                      });
                    }
                  } else {
                    window.location.reload();
                  }
                });
              },
              this.props.theme,
              "No"
            );
          }}
          key={real_key}
          name={this.props.integrations[real_key].name}
          logo={this.props.integrations[real_key].base64Image}
          
        />
      );

    });
  }

  render() {
    return (
      <div
        key="seatadjustcontainer"
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <p style={{ ...styles.p1, ...this.props }}>Choose Input Method</p>

        <NeuDiv
          color={this.props.theme.MAIN_BACKGROUND_COLOR}
          revert
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "space-evenly",
            width: "80%",
            padding: "2%",
            height: "75%",
          }}
        >
          {this.renderPlatforms()}
        </NeuDiv>
      </div>
    );
  }
}

const styles = {
  p1: {
    color: "rgb(230,230,230)",
    fontFamily: "Montserrat",
    fontSize: "4vw",
  },

  neudiv: {
    paddingTop: "5%",
    paddingBottom: "5%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  adjustButton: {
    width: "8vw",
    height: "8vw",
    marginLeft: "2vw",
    marginRight: "2vw",
    fontSize: "5vw",
    color: "rgb(230,230,230)",
  },
};
