import React, { Component } from "react";
import { Form, Input, Button, Checkbox, Card, Modal, Tabs, Table, Row, Col, Slider, Select, Space, notification } from "antd";
import { GetAdminSettings, SetAdminSettings, SetAxisControlToggle } from "../Redux/Actions/ApiActions";
import { connect } from "react-redux";
import Container from "./simulatorAnimation";
import { toggleDarkMode } from "../Redux/Actions/AppActions";
import { showLoading } from "../Common/Loading";

const { TabPane } = Tabs;
const { Option } = Select;

const layout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 18,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

class AdminPanel extends Component {
  constructor(props) {
    super(props);
    this.saveSettings = this.saveSettings.bind(this);
    this.tabBarCallback = this.tabBarCallback.bind(this);
    this.state = {
      selectedTab: "params",
      changes: {},
      selectedEngine: "X",
      mode: "dark",
      logged_in: false,
      user: {},
      axisControlSet: {},
    };
  }

  saveSettings() {
    let data = {};
    data[this.state.selectedEngine] = this.state.changes;
    SetAdminSettings(data).then((res) => {
      this.props.dispatch(GetAdminSettings());
    });
  }

  onLoginSubmit = async (values) => {
    localStorage.setItem("user", JSON.stringify(values));
    this.setState({ user: values, logged_in: true });
  };

  onLoginFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  componentDidMount() {
    let user: Object = JSON.parse(localStorage.getItem("user"));
    if (user) {
      this.setState({ user, logged_in: true });
    }
    if (this.props.theme.isDark) {
      require("antd/dist/antd-with-locales.min");
    } else {
      require("antd/dist/antd-with-locales.min");
    }
  }

  componentDidUpdate(prevProps) {
    let categories = [];

    if (prevProps.tcp_state !== this.props.tcp_state && this.props.tcp_state) {
      this.props.dispatch(GetAdminSettings());
    }

    if (this.props.settings !== prevProps.settings) {
      try {
        Object.keys(this.props.settings.connectionSettings?.engineSettings[this.state.selectedEngine]).map((key) => categories.push({ key: key, name: key.replaceAll("_", " ") }));

        this.setState({ categories: categories });
      } catch {}
    }
  }

  /*generatePassword() {
    var length = 8,
      charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  }*/

  tabBarCallback(key) {
    this.setState({ selectedTab: key });
  }

  renderPopInfo() {
    Modal.info({
      title: "Lütfen mail adresinize gelen kodu girin.",
      content: (
        <div>
          <p>some messages...some messages...</p>
          <p>some messages...some messages...</p>
        </div>
      ),
      onOk() {},
    });
  }

  renderParameters() {
    const columns = [{ title: "Category", dataIndex: "name", key: "category" }];

    const expandedRowRender = (records) => {
      let data = [];

      try {
        let temporary = this.props.settings.connectionSettings.engineSettings[this.state.selectedEngine][records.key];

        Object.keys(temporary).map((key) => {
          return data.push({
            ...temporary[key],
            parameter: key.replaceAll("_", " "),
            parentName: key,
          });
        });
      } catch {
        data = [];
      }

      const columns = [
        {
          title: "Parameter",
          dataIndex: "parameter",
          key: "key_category9",
        },
        {
          title: "Offline Value",
          dataIndex: "offline_value",
          key: "key_category10",
          render: (value, table) =>
            table.ValueType === "Boolean" ? (
              <Select
                onChange={(selectedValue) => {
                  let changes = this.state.changes;
                  changes[table.settingCategoryName] = {
                    ...changes[table.settingCategoryName],
                    [table.parentName]: { _Value: selectedValue },
                  };
                  this.setState({
                    changes,
                  });
                }}
                defaultValue={String(table._Value)}
                style={{ width: "100%" }}
              >
                <Option value="true">True</Option>
                <Option value="false">False</Option>
              </Select>
            ) : (
              <Input
                type="number"
                onChange={(e) => {
                  let changes = this.state.changes;
                  if (e.target.value === "") {
                    delete changes[table.settingCategoryName][table.parentName];
                    this.setState({ changes });
                  } else {
                    changes[table.settingCategoryName] = {
                      ...changes[table.settingCategoryName],
                      [table.parentName]: {
                        _Value: parseFloat(e.target.value),
                      },
                    };
                    this.setState({
                      changes,
                    });
                  }
                }}
                placeholder={table._Value}
              ></Input>
            ),
        },
        {
          title: "Online Value",
          dataIndex: "_Value",
          key: "key_category11",
          render: (value, table) => (table.ValueType === "Boolean" ? <p>{String(value)}</p> : <p>{value}</p>),
        },
        {
          title: "Type",
          dataIndex: "ValueType",
          key: "key_category11",
        },
      ];

      return <Table rowKey="parentName" columns={columns} dataSource={data} pagination={false} />;
    };

    return <Table style={{ maxWidth: "1000px" }} columns={columns} dataSource={this.state.categories} expandable={{ expandedRowRender }} />;
  }

  renderLoginView() {
    return (
      <div
        style={{
          display: "flex",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Card bordered={false} style={{ width: "30%", maxWidth: "400px" }}>
          <Form
            {...layout}
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinish={this.onLoginSubmit}
            onFinishFailed={this.onLoginFailed}
          >
            <Form.Item
              label="Username"
              name="username"
              rules={[
                {
                  required: true,
                  message: "Username field cannot be empty!",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: "Password field cannot be empty!",
                },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item {...tailLayout}>
              <Button type="primary" htmlType="submit">
                Sign In
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
    );
  }

  renderDynamicsView() {
    const renderBasicInput = (label, type, object) => {
      return (
        <Row>
          <Col span={12}>
            <p style={{ marginLeft: "5%" }}>{label}</p>
          </Col>
          <Col span={8}>
            <Input
              type="number"
              onChange={(e) => {
                let changes = this.state.changes;
                if (e.target.value === "") {
                  delete changes[object.settingCategoryName][object.parentName];
                  this.setState({ changes });
                } else {
                  changes[object.settingCategoryName] = {
                    ...changes[object.settingCategoryName],
                    [object.parentName]: {
                      _Value: parseFloat(e.target.value),
                    },
                  };
                  this.setState({
                    changes,
                  });
                }
              }}
              placeholder={object._Value}
            ></Input>
          </Col>
          <Col span={4} align="middle">
            <p>{type}</p>
          </Col>
        </Row>
      );
    };

    const renderBasicSlider = (label, maxValue, minValue, minLabel, maxLabel) => {
      return (
        <Row>
          <Col span={12}>
            <p style={{ marginLeft: "5%" }}>{label}</p>
          </Col>
          <Col span={11}>
            <Slider max={maxValue} min={minValue} marks={{ 0: minLabel, 100: maxLabel }} />
          </Col>
        </Row>
      );
    };

    return (
      <div style={{ maxWidth: "700px" }}>
        <Row>
          <Col span={24} style={{ border: "1px solid #343434" }}>
            <Row>
              <div style={{ display: "flex", padding: "2%" }}>
                <Checkbox disabled></Checkbox>
                <p>Indirect by Accelereation Time</p>
              </div>
            </Row>
            {renderBasicInput("Maximum Velocity (Vmax)", "Degree/s", 180)}
            {renderBasicInput("Acceleration Time", "s", 2)}
            {renderBasicInput("Deceeleration Time", "s", 2)}
            {renderBasicSlider("Acceleration Characteristic", 100, 0, "Smooth", "Stiff")}
            {renderBasicSlider("Deceleration Characteristic", 100, 0, "Smooth", "Stiff")}
          </Col>
          <Col span={24} style={{ border: "1px solid #343434", marginTop: "2%" }}>
            <Row>
              <div style={{ display: "flex", padding: "2%" }}>
                <Checkbox checked></Checkbox>
                <p>Direct</p>
              </div>
            </Row>
            {renderBasicInput(
              "Maximum Velocity (Vmax)",
              "Degree/s",
              Object.keys(this.props.settings).length > 1
                ? {
                    ...this.props.settings?.connectionSettings?.engineSettings[this.state.selectedEngine].Maximum_Dynamics.Maximum_Velocity,
                    parentName: "Maximum_Velocity",
                  }
                : {}
            )}
            {renderBasicInput(
              "Acceleration Time",
              "s",
              Object.keys(this.props.settings).length > 1
                ? {
                    ...this.props.settings?.connectionSettings?.engineSettings[this.state.selectedEngine].Default_Dynamics.Default_Acceleration,
                    parentName: "Default_Acceleration",
                  }
                : {}
            )}
            {renderBasicInput(
              "Deceleration Time",
              "s",
              Object.keys(this.props.settings).length > 1
                ? {
                    ...this.props.settings.connectionSettings.engineSettings[this.state.selectedEngine].Default_Dynamics.Default_Deceleration,
                    parentName: "Default_Deceleration",
                  }
                : {}
            )}
          </Col>
        </Row>
      </div>
    );
  }

  renderAxisControlView() {
    const renderBasicInput = (label, type, value, disabled?: Boolean) => {
      return (
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "flex-end",
            }}
          >
            <p style={{ marginBottom: 0 }}>{label}</p>
            <p style={{ marginBottom: 0 }}>{type}</p>
          </div>
          <Input disabled={disabled !== undefined ? disabled : true} placeholder={value}></Input>
        </div>
      );
    };

    const renderBasicCheckbox = (label: String, value, disabled?: Bool) => {
      return (
        <div
          style={{
            display: "flex",
          }}
        >
          <Checkbox
            onChange={(e) => {
              this.setState({
                axisControlSet: {
                  ...this.state.axisControlSet,
                  [label.replace(/\s/g, "")]: e.target.checked,
                },
              });
            }}
            defaultChecked={value}
            disabled={disabled !== undefined ? disabled : true}
            style={{ marginRight: "5px" }}
          ></Checkbox>
          <p>{label}</p>
        </div>
      );
    };

    let { Engines } = this.props.current_state;

    return (
      <div style={{ maxWidth: "1000px" }}>
        <Row gutter={[16, 8]}>
          <Col span={16} align="bottom">
            <Row gutter={[16, 8]}>
              <Col span={24}>
                <Input size="large" placeholder={Engines[this.state.selectedEngine].CurrentPosition}></Input>
              </Col>
              <Col span={12}>{renderBasicInput("Lag Distance (min/max)", "[mm]", Engines[this.state.selectedEngine].Status_Lag_Distance_Min_Max)}</Col>
              <Col span={12}>{renderBasicInput("Actual Velocity", "[mm/s]", Engines[this.state.selectedEngine].Status_Actual_Velocity)}</Col>
              <Col span={12}>{renderBasicInput("Override", "[%]", Engines[this.state.selectedEngine].Status_Override)}</Col>
              <Col span={12}>{renderBasicInput("Total/Control Output", "[%]", Engines[this.state.selectedEngine].Status_Total_Control_Output)}</Col>
            </Row>
          </Col>
          <Col span={8}>
            <Row>
              <Col span={24}>{renderBasicInput("Setpoint Position", "[mm]", Engines[this.state.selectedEngine].Status_Setpoint_Position)}</Col>
              <Col span={24}>{renderBasicInput("Setpoint Velocity", "[mm/s]", Engines[this.state.selectedEngine].Status_Setpoint_Velocity)}</Col>
              <Col span={24}>{renderBasicInput("Error", "", Engines[this.state.selectedEngine].Status_Error)}</Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={8}>
            <Row style={styles.checkboxRow}>
              <Col span={24}>
                <p>Status (log.)</p>
              </Col>
              <Col span={12}>{renderBasicCheckbox("Ready", Engines[this.state.selectedEngine].Status_Ready)}</Col>
              <Col span={12}>{renderBasicCheckbox("NOT Moving", Engines[this.state.selectedEngine].Status_Not_Moving)}</Col> <Col span={12}>{renderBasicCheckbox("Calibrated", Engines[this.state.selectedEngine].Status_Calibrated)}</Col> <Col span={12}>{renderBasicCheckbox("Moving Fw", Engines[this.state.selectedEngine].Status_Moving_Fw)}</Col> <Col span={12}>{renderBasicCheckbox("Has Job", Engines[this.state.selectedEngine].Status_Has_Job)}</Col> <Col span={12}>{renderBasicCheckbox("Moving Bw", Engines[this.state.selectedEngine].Status_Moving_Bw)}</Col>
            </Row>
          </Col>
          <Col span={8}>
            <Row style={styles.checkboxRow}>
              <Col span={24}>
                <p>Status (phys.)</p>
              </Col>
              <Col span={24}>{renderBasicCheckbox("Couple Mode", Engines[this.state.selectedEngine].Status_Couple_Mode)}</Col>
              <Col span={24}>{renderBasicCheckbox("In Target Position", Engines[this.state.selectedEngine].Status_In_Target_Pos)}</Col>
              <Col span={24}>{renderBasicCheckbox("In Position Range", Engines[this.state.selectedEngine].Status_In_Pos_Range)}</Col>
            </Row>
          </Col>
          <Col span={8}>
            <Row style={styles.checkboxRow}>
              <Col span={18}>
                <Row>
                  <Col span={24}>
                    <p>Enabling</p>
                  </Col>
                  <Col span={24}>{renderBasicCheckbox("Controller", Engines[this.state.selectedEngine].Status_Controller, false)}</Col>
                  <Col span={24}>{renderBasicCheckbox("Feed Fw", Engines[this.state.selectedEngine].Status_Feed_Fw, false)}</Col>
                  <Col span={24}>{renderBasicCheckbox("Feed Bw", Engines[this.state.selectedEngine].Status_Feed_Bw, false)}</Col>
                </Row>
              </Col>
              <Col span={6}>
                <Button
                  onClick={() => {
                    if (Object.keys(this.state.axisControlSet).length !== 0)
                      SetAxisControlToggle({
                        ...this.state.axisControlSet,
                        EngineType: this.state.selectedEngine,
                      });
                  }}
                >
                  Set
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={12}>{renderBasicInput("Controller Kv-Factor", "[mm/s/mm]", Engines[this.state.selectedEngine].Status_Controller_Kv_Factor)}</Col>
          <Col span={12}>{renderBasicInput("Referance Velocity", "[mm/s]", Engines[this.state.selectedEngine].Status_Referance_Velocity)}</Col>
          <Col span={12}>{renderBasicInput("Target Position", "[mm]", Engines[this.state.selectedEngine].Status_Target_Position)}</Col>
          <Col span={12}>{renderBasicInput("Target Velocity", "[mm/s]", Engines[this.state.selectedEngine].Status_Target_Velocity)}</Col>
        </Row>
      </div>
    );
  }

  renderEngineOptions() {
    try {
      return Object.keys(this.props.settings.connectionSettings.engineSettings).map((key) => {
        return (
          <Option key={"option" + key} value={key}>
            Engine {key}
          </Option>
        );
      });
    } catch {
      return null;
    }
  }

  renderMainView() {
    const operations = (
      <div style={{ display: "flex" }}>
        <Select
          defaultValue="X"
          onChange={(index) => {
            if (Object.keys(this.state.changes).length === 0) {
              this.setState({ selectedEngine: index });
            } else {
              notification.error({
                message: "Please save or abort changes.",
                placement: "topRight",
              });
              //message.error("Please save or abort changes.");
            }
          }}
          style={{ width: 120, marginRight: "2%" }}
        >
          {this.renderEngineOptions()}
        </Select>
        <Select
          value={this.props.theme.isDark ? "dark" : "light"}
          onChange={(value) => {
            this.props.dispatch(toggleDarkMode());
          }}
        >
          <Option value="dark">Dark Mode</Option>
          <Option value="light">Light Mode</Option>
        </Select>
      </div>
    );
    return (
      <div style={{...styles.mainContainer,backgroundColor: this.props.theme.isDark ? '#1d1f21' : '#fff'}}>
        {() => {
          if (!this.props.tcp_state) {
            showLoading(null, "Cannot connect to TwinCat.", 40);
          }
        }}

        <Tabs style={{ marginBottom: "2%" }} defaultActiveKey="1" onChange={this.tabBarCallback} tabBarExtraContent={operations}>
          <TabPane tab="Paremeters" key="params">
            {this.renderParameters()}
          </TabPane>
          <TabPane tab="Dynamics" key="dynamics">
            {this.renderDynamicsView()}
          </TabPane>
          <TabPane tab="Axis Control" key="axiscontrol">
            {this.renderAxisControlView()}
          </TabPane>
          <TabPane tab="Simulator" key="simulator">
            <Container selectedEngine={this.state.selectedEngine} theme={this.props.theme}></Container>
          </TabPane>
        </Tabs>
        {this.state.selectedTab === "params" || this.state.selectedTab === "dynamics" ? (
          <Space>
            <Button
              onClick={() => {
                this.props.history.go(0);
              }}
              style={{ top: "10%" }}
              size="large"
            >
              Reset
            </Button>
            <Button onClick={this.saveSettings} style={{ top: "10%" }} size="large" type="primary">
              Save
            </Button>
          </Space>
        ) : null}
      </div>
    );
  }

  render() {
    return this.state.logged_in ? this.renderMainView() : this.renderLoginView();
  }
}

const mapStateToProps = (state) => ({
  current_state: state.sensors.current_state,
  tcp_state: state.sensors.current_state.IsTwinCatConnectionEstablished,
  settings: state.settings.settings,
});

export default connect(mapStateToProps)(AdminPanel);

const styles = {
  mainContainer: {
    padding: "1%",
    position: "fixed",
    width: "100%",
    height: "100%",
    left: 0,
    top: 0,
    overflowY: "scroll",
    zIndex: 10,
  },
  checkboxRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "2%",
  },
};
