export const DARK_MODE_SWITCHER = "DARK_MODE_SWITCHER";

export const toggleDarkMode = () => {
  window.location.reload();
  return async (dispatch) => {
    dispatch({
      type: DARK_MODE_SWITCHER,
    });
  };
};
