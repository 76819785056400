import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import FirstView from "./Screens/firstView";
import MainScreen from "./Screens/mainScreen";
import { connect } from "react-redux";
import { connectTcpServer } from "./Redux/Actions/TcpActions";
import { GetIntegrations,connectApi } from "./Redux/Actions/ApiActions";
import AdminPanel from "./Screens/adminPanel";
import { Button, Input } from "antd";
import Modal from "antd/lib/modal/Modal";
import { getIpAddressFromLocalStorage } from "./Common/Helper";

import { darkTheme, lightTheme } from "./constants";

const { IP_ADDRESS, WEBSOCKET_PORT, API_PORT } = getIpAddressFromLocalStorage();

class App extends Component {
  constructor(props) {
    super(props);
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.handleOk = this.handleOk.bind(this);
    this.state = { visible: false };
  }

  componentDidMount() {
    connectApi(this.showModal);
    this.props.dispatch(connectTcpServer(this.showModal, this.hideModal));
    this.props.dispatch(GetIntegrations());
    /*overrideThemeVariables({
      '--light-bg': '#E9B7B9',
      '--light-bg-dark-shadow': '#ba9294',
      '--light-bg-light-shadow': '#ffdcde',
      '--dark-bg': '#292E35',
      '--dark-bg-dark-shadow': '#21252a',
      '--dark-bg-light-shadow': '#313740',
      '--primary': '#8672FB',
      '--primary-dark': '#4526f9',
      '--primary-light': '#c7befd'
    })*/
  }

  showModal = () => {
    if (!this.state.visible) {
      this.setState({ visible: true });
    }
  };

  hideModal = () => {
    if (this.state.visible) {
      this.setState({ visible: false });
    }
  };

  handleOk() {
    const { IP_ADDRESS, WEBSOCKET_PORT, API_PORT } = this.state;
    if (IP_ADDRESS !== "" && IP_ADDRESS !== undefined) {
      localStorage.setItem("IP_ADDRESS", this.state.IP_ADDRESS);
    }
    if (WEBSOCKET_PORT !== "" && WEBSOCKET_PORT !== undefined) {
      localStorage.setItem("WEBSOCKET_PORT", this.state.WEBSOCKET_PORT);
    }
    if (API_PORT !== "" && API_PORT !== undefined) {
      localStorage.setItem("API_PORT", this.state.API_PORT);
    }

    this.props.dispatch(connectTcpServer(this.showModal, this.hideModal));
    this.props.dispatch(GetIntegrations());

    this.setState({ visible: false });
  }

  defaultParams() {
    var currentIp = window.location.hostname;
    localStorage.setItem("IP_ADDRESS", currentIp);
    localStorage.setItem("WEBSOCKET_PORT", 5000);
    localStorage.setItem("API_PORT", 5000);
    this.setState({ visible: false });
  }

  render() {
    return (
      <Router>
        <Modal
          mask
          centered
          title="Can not connect NGS WebAPI"
          maskClosable={false}
          open={this.state.visible}
          onCancel={() => {
            this.setState({ visible: false });
            this.props.dispatch(connectTcpServer(this.showModal, this.hideModal));
            this.props.dispatch(GetIntegrations());
          }}
          footer={[
            <Button onClick={this.defaultParams}>Default Settings</Button>,
            <Button key="submit" type="primary" onClick={this.handleOk}>
              Save
            </Button>,
          ]}
        >
          <Input onChange={(e) => this.setState({ IP_ADDRESS: e.target.value })} type="url" addonBefore="Ip Address" defaultValue={IP_ADDRESS} />
          <Input onChange={(e) => this.setState({ API_PORT: e.target.value })} type="number" addonBefore="API Port" defaultValue={API_PORT} />
          <Input onChange={(e) => this.setState({ WEBSOCKET_PORT: e.target.value })} type="number" addonBefore="Websocket Port" defaultValue={WEBSOCKET_PORT} />
        </Modal>
        <Switch>
          <Route exact key="firstView" path="/" component={() => <FirstView theme={this.props.isDarkMode ? darkTheme : lightTheme} />}></Route>
          <Route exact key="main" path="/main" component={({ location }) => <MainScreen location={location} theme={this.props.isDarkMode ? darkTheme : lightTheme} />} theme={darkTheme}></Route>
          <Route exact key="admin" path="/admin" component={() => <AdminPanel key="adminpanelscreen" theme={this.props.isDarkMode ? darkTheme : lightTheme} />}></Route>
        </Switch>
      </Router>
    );
  }
}

const mapStateToProps = (state) => ({
  isDarkMode: state.settings.settings.isDarkMode,
});

export default connect(mapStateToProps)(App);
//