import { showLoading, hideLoading } from "../../Common/Loading";
import Axios from "axios";
import { notification } from "antd";
import { checkResponse, getIpAddressFromLocalStorage, isNullorUndefined } from "../../Common/Helper";
import { renderTwincatModal } from '../../Common/TwincatModal';
import * as dayjs from 'dayjs'
import * as isLeapYear from 'dayjs/plugin/isLeapYear' // import plugin
import 'dayjs/locale/en' // import locale

dayjs.extend(isLeapYear) // use plugin
dayjs.locale('en') // use locale

export const TWINCAT_SETTINGS = "TWINCAT_SETTINGS";
export const TWINCAT_ADMIN_SETTINGS = "TWINCAT_ADMIN_SETTINGS";
export const GET_STATE_FROM_API = "GET_STATE_FROM_API";
export const ERROR = "ERROR";
export const ENTEGRATIONS = "ENTEGRATIONS";
export const GET_ALL_LOGS = "GEL_ALL_LOGS";

export const connectApi = () => {
  const { IP_ADDRESS, API_PORT } = getIpAddressFromLocalStorage();

  showLoading(null, null, 40).then((ref) => {
    Axios.get("http://" + IP_ADDRESS + ":" + API_PORT + "/api/MotionPlatform/OpenConnection/", { timeout: 7000 })
      .then((res) => {
        checkResponse(res);
        if (res.data.HasError) {
          renderTwincatModal();
        }
        hideLoading(ref);
      })
      .catch((err) => {
        hideLoading(ref);

      });
  });
};

export const getLogs = (onReceive) => {
  const { IP_ADDRESS, API_PORT } = getIpAddressFromLocalStorage();

  return async (dispatch) => {
    Axios.get("http://" + IP_ADDRESS + ":" + API_PORT + "/api/log", {})
      .then((result) => {
        onReceive();
        if (result.status === 200) {
          let dateModifiedData = result.data.returnObject.map((log) => {
            log.timeGenerated = dayjs(log.timeGenerated).format('D MMMM YYYY, HH:mm');
            console.log(log)
            return log;
          });
          dispatch({
            type: GET_ALL_LOGS,
            payload: dateModifiedData,
          });
        }
      })
      .catch((err) => {});
  };
};

export const unConnectApi = () => {
  const { IP_ADDRESS, API_PORT } = getIpAddressFromLocalStorage();

  showLoading(null, null, 40).then((ref) => {
    Axios.get("http://" + IP_ADDRESS + ":" + API_PORT + "/api/MotionPlatform/CloseConnection/", {})
      .then((res) => {
        hideLoading(ref);
      })
      .catch((err) => {});
  });
};

export const powerOn = () => {
  const { IP_ADDRESS, API_PORT } = getIpAddressFromLocalStorage();

  showLoading(null, null, 40).then((ref) => {
    Axios.get("http://" + IP_ADDRESS + ":" + API_PORT + "/api/MotionPlatform/PowerOn/", {})
      .then((res) => {
        checkResponse(res);

        hideLoading(ref);
      })
      .catch((err) => {});
  });
};

export const powerOff = () => {
  const { IP_ADDRESS, API_PORT } = getIpAddressFromLocalStorage();

  showLoading(null, null, 40).then((ref) => {
    Axios.get("http://" + IP_ADDRESS + ":" + API_PORT + "/api/MotionPlatform/PowerOff/", {})
      .then((res) => {
        checkResponse(res);

        hideLoading(ref);
      })
      .catch((err) => {});
  });
};
export const PowerControl = () => {
  const { IP_ADDRESS, API_PORT } = getIpAddressFromLocalStorage();

  showLoading(null, null, 40).then((ref) => {
    Axios.get("http://" + IP_ADDRESS + ":" + API_PORT + "/api/MotionPlatform/ResetError", {})
      .then((res) => {
        checkResponse(res);

        hideLoading(ref);
      })
      .catch((err) => {});
  });
};
export const ResetError = () => {
  const { IP_ADDRESS, API_PORT } = getIpAddressFromLocalStorage();

  showLoading(null, null, 40).then((ref) => {
    Axios.get("http://" + IP_ADDRESS + ":" + API_PORT + "/api/MotionPlatform/ResetError", {})
      .then((res) => {
        checkResponse(res);

        hideLoading(ref);
      })
      .catch((err) => {});
  });
};

export const Calibrate = () => {
  const { IP_ADDRESS, API_PORT } = getIpAddressFromLocalStorage();

  showLoading(null, null, 40).then((ref) => {
    Axios.get("http://" + IP_ADDRESS + ":" + API_PORT + "/api/MotionPlatform/Calibrate", {})
      .then((res) => {
        checkResponse(res);

        hideLoading(ref);
      })
      .catch((err) => {});
  });
};

export const EnableExactPosition = (ref) => {
  const { IP_ADDRESS, API_PORT } = getIpAddressFromLocalStorage();

  if (isNullorUndefined(ref)) {
    showLoading(null, null, 40).then((newRef) => {
      Axios.get("http://" + IP_ADDRESS + ":" + API_PORT + "/api/MotionPlatform/EnableExactPosition/", {})
        .then((res) => {
          checkResponse(res);
          GetIntegrations();
          hideLoading(newRef);
        })
        .catch((err) => {
          hideLoading(newRef);
        });
    });
  } else {
    Axios.get("http://" + IP_ADDRESS + ":" + API_PORT + "/api/MotionPlatform/EnableExactPosition/", {})
      .then((res) => {
        checkResponse(res);
        GetIntegrations();
        hideLoading(ref);
      })
      .catch((err) => {
        hideLoading(ref);
      });
  }
};

export const DisableExactPosition = (ref) => {
  const { IP_ADDRESS, API_PORT } = getIpAddressFromLocalStorage();

  if (isNullorUndefined(ref)) {
    showLoading(null, null, 40).then((newRef) => {
      Axios.get("http://" + IP_ADDRESS + ":" + API_PORT + "/api/MotionPlatform/DisableExactPosition/", {})
        .then((res) => {
          checkResponse(res);

          hideLoading(newRef);
        })
        .catch((err) => {
          hideLoading(ref);
        });
    });
  } else {
    Axios.get("http://" + IP_ADDRESS + ":" + API_PORT + "/api/MotionPlatform/DisableExactPosition/", {})
      .then((res) => {
        checkResponse(res);

        hideLoading(ref);
      })
      .catch((err) => {
        hideLoading(ref);
      });
  }
};

export const SetZero = async () => {
  const { IP_ADDRESS, API_PORT } = getIpAddressFromLocalStorage();

  showLoading(null, null, 40).then((ref) => {
    Axios.get("http://" + IP_ADDRESS + ":" + API_PORT + "/api/MotionPlatform/SetPositionZero", {})
      .then((res) => {
        checkResponse(res);

        hideLoading(ref);
      })
      .catch((err) => {
        hideLoading(ref);
      });
  });
};

export const powerOnAxis = (axis) => {
  const { IP_ADDRESS, API_PORT } = getIpAddressFromLocalStorage();

  showLoading(null, null, 40).then((ref) => {
    Axios.get("http://" + IP_ADDRESS + ":" + API_PORT + "/api/MotionPlatform/PowerOnAxis/" + axis, {})
      .then((res) => {
        checkResponse(res);

        hideLoading(ref);
      })
      .catch((err) => {
        hideLoading(ref);
      });
  });
};

export const powerOffAxis = (axis) => {
  const { IP_ADDRESS, API_PORT } = getIpAddressFromLocalStorage();

  showLoading(null, null, 40).then((ref) => {
    Axios.get("http://" + IP_ADDRESS + ":" + API_PORT + "/api/MotionPlatform/PowerOffAxis/" + axis, {})
      .then((res) => {
        checkResponse(res);

        hideLoading(ref);
      })
      .catch((err) => {
        hideLoading(ref);
      });
  });
};

export const ResetErrorAxis = (axis) => {
  const { IP_ADDRESS, API_PORT } = getIpAddressFromLocalStorage();

  showLoading(null, null, 40).then((ref) => {
    Axios.get("http://" + IP_ADDRESS + ":" + API_PORT + "/api/MotionPlatform/ResetErrorAxis/" + axis, {})
      .then((res) => {
        checkResponse(res);

        hideLoading(ref);
      })
      .catch((err) => {
        hideLoading(ref);
      });
  });
};

export const CalibrateAxis = (axis) => {
  const { IP_ADDRESS, API_PORT } = getIpAddressFromLocalStorage();

  showLoading(null, null, 40).then((ref) => {
    Axios.get("http://" + IP_ADDRESS + ":" + API_PORT + "/api/MotionPlatform/CalibrateAxis/" + axis, {})
      .then((res) => {
        checkResponse(res);

        hideLoading(ref);
      })
      .catch((err) => {
        hideLoading(ref);
      });
  });
};

export const EnableExactPositionAxis = (axis) => {
  const { IP_ADDRESS, API_PORT } = getIpAddressFromLocalStorage();

  showLoading(null, null, 40).then((ref) => {
    Axios.get("http://" + IP_ADDRESS + ":" + API_PORT + "/api/MotionPlatform/EnableExactPositionAxis/" + axis, {})
      .then((res) => {
        checkResponse(res);

        hideLoading(ref);
      })
      .catch((err) => {
        hideLoading(ref);
      });
  });
};

export const DisableExactPositionAxis = (axis) => {
  const { IP_ADDRESS, API_PORT } = getIpAddressFromLocalStorage();

  showLoading(null, null, 40).then((ref) => {
    Axios.get("http://" + IP_ADDRESS + ":" + API_PORT + "/api/MotionPlatform/DisableExactPositionAxis/" + axis, {})
      .then((res) => {
        checkResponse(res);

        hideLoading(ref);
      })
      .catch((err) => {
        hideLoading(ref);
      });
  });
};

export const SetZeroAxis = async (axis) => {
  const { IP_ADDRESS, API_PORT } = getIpAddressFromLocalStorage();

  showLoading(null, null, 40).then((ref) => {
    Axios.get("http://" + IP_ADDRESS + ":" + API_PORT + "/api/MotionPlatform/SetPositionZeroAxis/" + axis, {})
      .then((res) => {
        checkResponse(res);

        hideLoading(ref);
      })
      .catch((err) => {
        hideLoading(ref);
      });
  });
};

export const GetSettings = () => {
  const { IP_ADDRESS, API_PORT } = getIpAddressFromLocalStorage();

  return async (dispatch) => {
    Axios.get("http://" + IP_ADDRESS + ":" + API_PORT + "/api/Settings", {})
      .then((res) => {
        checkResponse(res);
        dispatch({ type: TWINCAT_SETTINGS, payload: res.data });
      })
      .catch((err) => {});
  };
};

/*Önemli kaldı*/ export const SetAxisControlToggle = (data) => {
  const { IP_ADDRESS, API_PORT } = getIpAddressFromLocalStorage();

  Axios.post("http://" + IP_ADDRESS + ":" + API_PORT + "/api/MotionPlatform/SetStatusEnabling", data)
    .then((res) => {
      checkResponse(res);
    })
    .catch((err) => {});
};

export const enginePositionIncrease = (engine, active) => {
  const { IP_ADDRESS, API_PORT } = getIpAddressFromLocalStorage();

  Axios.get("http://" + IP_ADDRESS + ":" + API_PORT + "/api/MotionPlatform/ManualControlUp" + (active ? "Active/" : "Passive/") + engine, {})
    .then((res) => {
      checkResponse(res);
    })
    .catch((err) => {});
};

export const enginePositionDecrease = (engine, active) => {
  const { IP_ADDRESS, API_PORT } = getIpAddressFromLocalStorage();

  Axios.get("http://" + IP_ADDRESS + ":" + API_PORT + "/api/MotionPlatform/ManualControlDown" + (active ? "Active/" : "Passive/") + engine, {})
    .then((res) => {})
    .catch((err) => {});
};

export const SetAdminSettings = (data) => {
  const { IP_ADDRESS, API_PORT } = getIpAddressFromLocalStorage();

  return new Promise((resolve, reject) => {
    Axios.post("http://" + IP_ADDRESS + ":" + API_PORT + "/api/Settings/admin", data)
      .then((res) => {
        checkResponse(res);
        if (res.status === 200 || res.status === 204) {
          resolve();
        }
      })
      .catch((err) => {});
  });
};

export const GetAdminSettings = () => {
  const { IP_ADDRESS, API_PORT } = getIpAddressFromLocalStorage();

  return async (dispatch) => {
    showLoading(null, null, 40).then((ref) => {
      Axios.get("http://" + IP_ADDRESS + ":" + API_PORT + "/api/Settings/admin", {})
        .then((res) => {
          hideLoading(ref);
          dispatch({ type: TWINCAT_ADMIN_SETTINGS, payload: res.data });
        })
        .catch((err) => {
          dispatch(GetAdminSettings());
        });
    });
  };
};

export const SettingsButtonPressed = (obj, key) => {
  const { IP_ADDRESS, API_PORT } = getIpAddressFromLocalStorage();

  return async (dispatch) => {
    Axios.post("http://" + IP_ADDRESS + ":" + API_PORT + "/api/Settings/" + key, obj)
      .then((res) => {
        checkResponse(res);
      })
      .catch((err) => {});
  };
};

export const SettingsSaved = (obj) => {
  const { IP_ADDRESS, API_PORT } = getIpAddressFromLocalStorage();

  Axios.post("http://" + IP_ADDRESS + ":" + API_PORT + "/api/Settings/MotionPlatform/", obj)
    .then((res) => {
      checkResponse(res);
      connectApi();
    })
    .catch((err) => {});
};

export const GetIntegrations = () => {
  const { IP_ADDRESS, API_PORT } = getIpAddressFromLocalStorage();

  return async (dispatch) => {
    showLoading(null, null, 40).then((ref) => {
      Axios.get("http://" + IP_ADDRESS + ":" + API_PORT + "/api/Integration")
        .then((res) => {
          hideLoading(ref);
          if (res.status === 200) {
            checkResponse(res);
            localStorage.setItem("integrations", JSON.stringify(res.data));
            dispatch({ type: ENTEGRATIONS, payload: res.data });
            hideLoading(ref);
          }
          hideLoading(ref);
        })
        .catch((err) => {
          hideLoading(ref);
          notification.error({
            message: "Cannot get integrations.",
            placement: "topRight",
          });
          //message.error("Cannot get integrations.");
        });
    });
  };
};

export const IntegrationSTARTSTOP = (key, state) => {
  const { IP_ADDRESS, API_PORT } = getIpAddressFromLocalStorage();

  if (state) {
    if (key) {
      showLoading(null, null, 40).then((ref) => {
        switch (state) {
          case "START":
            Axios.post("http://" + IP_ADDRESS + ":" + API_PORT + "/api/Integration/Start/" + key)
              .then((res) => {
                checkResponse(res);
                hideLoading(ref);
              })
              .catch((err) => {
                hideLoading(ref);
              });
            break;

          case "STOP":
            Axios.post("http://" + IP_ADDRESS + ":" + API_PORT + "/api/Integration/Stop/" + key)
              .then((res) => {
                checkResponse(res);
                hideLoading(ref);
              })
              .catch((err) => {
                hideLoading(ref);
              });
            break;
          default:
            hideLoading(ref);
            return null;
        }
      });
    }
  } else {
    notification.warning({
      message: "Please select a game.",
      placement: "topRight",
    });
    //message.warning("Please select a game.");
  }
};
