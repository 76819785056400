import React, { Component } from "react";
import "../css/basicTheme.css";
import SeatBelts from "../Components/firstView_SeatBelts";
import { animated } from "react-spring";
import { Transition } from "react-spring/renderprops";
import AdjustSeat from "../Components/firstView_AdjustSeat";
import { NeuDiv, NeuButton } from "neumorphism-react";
import PlatformSelect from "../Components/firstView_PlatformSelect";
import { withRouter } from "react-router-dom";
import { renderPopUp } from "../Common/PopUp";
import { compose } from "redux";
import { connect } from "react-redux";
import { Switch } from "antd";
import { toggleDarkMode } from "../Redux/Actions/AppActions";
import { checkBelts } from "../Common/Helper";
import _ from "lodash";

class FirstView extends Component {
  state = {
    belt: false,
    pageIndex: 0,
  };

  styles = {
    neudiv: {
      paddingTop: "5%",
      paddingBottom: "5%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    mainContainer: {
      height: "100vh",
      display: "flex",
      backgroundColor: this.props.theme.MAIN_BACKGROUND_COLOR,
      alignItems: "center",
      justifyContent: "center",
    },
    pingText: {
      margin: "auto",
      marginRight: "10px",
      color: this.props.theme.MAIN_TEXT_COLOR,
      fontFamily: "Montserrat",
      fontWeight: "bold",
      fontSize: "0.5vw",
    },
  };

  confirmCalibration = () => {
    setTimeout(() => {
      this.setState({ belt: false, pageIndex: 2 });
    }, 800);
  };

  componentDidMount() {
    if (checkBelts(this.props.seatBeltStatus)) {
      this.setState({ belt: true, pageIndex: 1 });
    }
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(this.props.seatBeltStatus, prevProps.seatBeltStatus)) {
      console.log(this.props.seatBeltStatus);
      console.log(prevProps.seatBeltStatus);
      if (checkBelts(this.props.seatBeltStatus)) {
        this.setState({ belt: true, pageIndex: 1 });
      }
    }
  }

  renderHelper(index) {
    return <PlatformSelect theme={this.props.theme} integrations={this.props.integrations} isConnected={this.props.isConnected} historyPush={this.props.history.push} key="platformSelect"></PlatformSelect>;
    
    /*if (index === 0) {
      return (
        <SeatBelts
          theme={this.props.theme}
          key="seatbelts"
          SeatBelt={this.props.seatBeltStatus}
          onBeltFastened={() => {
            this.setState({ belt: true, pageIndex: 1 });
          }}
          hideModal={() => {}}
        />
      );
    } 
    else if (index === 1) {
      return <AdjustSeat theme={this.props.theme} showConfirmButton showHeader confirmCalibration={this.confirmCalibration} key="adjustSeat" />;
    } 
    else if (index === 1) {
      return <PlatformSelect theme={this.props.theme} integrations={this.props.integrations} isConnected={this.props.isConnected} historyPush={this.props.history.push} key="platformSelect"></PlatformSelect>;
    }*/
  }

  render() {
    const { pageIndex } = this.state;
    return (
      <div style={this.styles.mainContainer}>
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignContent: "center",
              alignItems: "center",
              justifyContent: "center",
              position: "absolute",
              right: "1%",
              top: "20px",
            }}
          >
            <div
              style={{ display: "flex", flexDirection: "row", cursor: "pointer" }}
              onClick={() => {
                if (!this.props.isConnected) {
                  this.props.history.push({ pathname: "/main", state: { TwincatWrongIp: true } });
                }
              }}
            >
              <p style={this.styles.pingText}>{this.props.isConnected ? "Device Connected" : "No Device Connection"}</p>{" "}
              <div
                style={{
                  alignSelf: "center",
                  margin: "auto",
                  backgroundColor: this.props.isConnected ? "#80914a" : "#a83e3e",
                  width: "0.7vw",
                  height: "0.7vw",
                  borderRadius: "100%",
                }}
              />
            </div>

            <Switch
              style={{ position: "absolute", right: "5%", top: "20px" }}
              checkedChildren="Light"
              unCheckedChildren="Dark"
              onChange={(checked) => {
                this.props.dispatch(toggleDarkMode());
              }}
              checked={this.props.theme.isDark}
            />
          </div>
        </div>

        <Transition
          initial={null}
          config={{ duration: 1000 }}
          reset
          unique
          items={pageIndex}
          from={{
            position: "absolute",
            opacity: 0,
            transform: "translate3d(0, 100vh ,0)",
          }}
          enter={{
            opacity: 1,
            transform: "translate3d(0, 0vh, 0)",
          }}
          leave={{
            opacity: 0,
            transform: "translate3d(0, -300vh, 0)",
          }}
        >
          {(index) => (style) =>
            (
              <animated.div style={{ ...style }}>
                <NeuDiv width="70vw" height="70vmin" color={this.props.theme.MAIN_BACKGROUND_COLOR} style={this.styles.neudiv}>
                  {this.renderHelper(index)}
                </NeuDiv>
              </animated.div>
            )}
        </Transition>
        <Transition
          initial={null}
          config={{ duration: 2000 }}
          reset
          unique
          items={pageIndex}
          from={{
            myOpacity: 0,
          }}
          enter={{
            myOpacity: 1,
          }}
          leave={{
            myOopacity: 0,
          }}
        >
          {(index) => (style) =>
            this.state.belt ? (
              <animated.div style={{ opacity: style.myOpacity }}>
                <NeuButton
                  onClick={() => {
                    renderPopUp(this, "Device will be calibrated!!!", "Confirm", this.confirmCalibration, this.props.theme);
                  }}
                  color={this.props.theme.MAIN_BACKGROUND_COLOR}
                  style={{
                    position: "absolute",
                    bottom: "1vh",
                    right: "1vw",
                    fontSize: "1.5vw",
                    fontFamily: "MontserratBold",
                    color: "rgb(230,230,230)",
                    margin: 0,
                    width: "10vw",
                    height: "5vw",
                  }}
                >
                  Start
                </NeuButton>
              </animated.div>
            ) : null}
        </Transition>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isConnected: state.sensors.current_state.IsTwinCatConnectionEstablished,
  seatBeltStatus: state.sensors.current_state.SeatBelt,
  integrations: state.sensors.integrations,
});

export default compose(withRouter, connect(mapStateToProps))(FirstView);
