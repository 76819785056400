import React from "react";
import { createRoot } from 'react-dom/client';
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { configureStore } from '@reduxjs/toolkit'
import { Provider } from "react-redux";
import reducer from "./Redux/Reducers";
import { ConfigProvider, Button, theme } from 'antd';

export const store = configureStore({ reducer: reducer })

const root = createRoot(document.getElementById('root'));

root.render(
  <ConfigProvider store={store} theme={{
    algorithm: theme.defaultAlgorithm,
  }}>
    <Provider store={store}>
      <App />
    </Provider>
  </ConfigProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
