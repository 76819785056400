import {combineReducers} from "redux";

import SettingsReducer from "./SettingsReducer";
import SensorReducer from "./SensorReducer";

const rootReducer = combineReducers({
    sensors: SensorReducer,
    settings: SettingsReducer
});

export default rootReducer;
