import React, { useEffect, useState } from "react";
import { useSpring, animated } from "react-spring";
/*
div.a {
  transform: rotate(20deg);
}
*/
export default function Gauge(props) {
  const [currentValue, setCurrentValue] = useState(0);
  const { value, valueType } = props;
  useEffect(() => {
    // Update the document title using the browser API
    setCurrentValue(props.value);
  }, [props.value]);

  const transform = useSpring({
    to: {
      transform: "rotate(" + value < 0 ? (value % 360) + 360 : value + "deg)",
    },
    from: { transform: "rotate(" + currentValue + "deg)" },
  });

  return (
    <>
      <animated.img
        style={{
          position: "absolute",
          maxHeight: "65%",
          top: "15%",
          objectFit: "contain",
          ...transform,
        }}
        src={require("../images/circle_gauge.png")}
      ></animated.img>
      <p
        style={{
          color: "rgb(230,230,230)",
          position: "absolute",
          top: "50%",
          transform: "translateY(-50%)",
        }}
      >{`${value}${valueType}`}</p>
    </>
  );
}
