import React, { Component } from "react";
import { NeuDiv, NeuButton } from "neumorphism-react";
import { createRoot } from 'react-dom/client';
import { Spring } from "react-spring/renderprops";
import { Divider } from 'antd';

export const portalContainer =  createRoot(document.getElementById("popup"));

export function renderPopUp(param, message, buttonText, onConfirm, theme, buttonCancelText) {
  portalContainer.render(
    <PopUp param={param} onConfirm={onConfirm} buttonText={buttonText} buttonCancelText={buttonCancelText} theme={theme}>
      {message}
    </PopUp>
  );
}

class PopUp extends Component {
  state = {
    toggle: true,
  };

  onCancel() {
    this.setState({ toggle: false });
    setTimeout(() => {
      portalContainer.render(null);
    }, 700);
  }

  render() {
    return (
      <Spring
        from={{
          opacity: this.state.toggle ? 0 : 1,
        }}
        to={{
          opacity: this.state.toggle ? 1 : 0,
        }}
        config={{ duration: 500 }}
      >
        {(props) => (
          <div style={{ ...props, ...style.mainContainerStyle }} onClick={this.onCancel.bind(this)}>
            <NeuDiv
              onClick={(event) => {
                event.stopPropagation();
              }}
              style={style.containerStyle}
              color={this.props.color ? this.props.color : this.props.theme.MAIN_BACKGROUND_COLOR}
            >
              <p style={{ ...style.text, color: this.props.theme.MAIN_TEXT_COLOR, fontFamily: "MontserratBold" }}> {this.props.children}</p>
              <Divider/>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  justifyContent: "space-evenly",
                  position: "relative",
                }}
              >
                <NeuButton
                  onClick={async () => {
                    await this.props.onConfirm();
                    this.onCancel();
                  }}
                  style={{
                    color: this.props.theme.MAIN_TEXT_COLOR,
                    fontFamily: "MontserratBold",
                    fontSize: "2vw",
                    width: "17vw",
                    height: "10vw",
                  }}
                  color={this.props.theme.MAIN_BACKGROUND_COLOR}>
                  {this.props.buttonText}
                </NeuButton>
                <NeuButton
                  onClick={() => {
                    this.onCancel();
                  }}
                  style={{
                    color: this.props.theme.MAIN_TEXT_COLOR,
                    fontFamily: "MontserratBold",
                    fontSize: "2vw",
                    width: "17vw",
                    height: "10vw",
                  }}
                  color={this.props.theme.MAIN_BACKGROUND_COLOR}>
                  {this.props.buttonCancelText ? this.props.buttonCancelText : "Cancel"}
                </NeuButton>
              </div>
            </NeuDiv>
          </div>
        )}
      </Spring>
    );
  }
}

const style = {
  text: {
    //color: "rgb(230, 230, 230)",
    fontFamily: "Montserrat",
    fontSize: "2vw",
    textAlign: "center",
  },
  mainContainerStyle: {
    backdropFilter: "blur(10px)",
    position: "absolute",
    left: 0,
    top: 0,
    zIndex: 1,
    backgroundColor: "rgba(39, 42, 47, 0.8)",
    width: "100vw",
    height: "100vh",
  },
  containerStyle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
    zIndex: 10,
    width: "50vw",
    height: "30vw",
  },
};
