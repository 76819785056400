var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import { forwardRef, useContext, useImperativeHandle } from 'react';
import CalendarOutlined from '@ant-design/icons/CalendarOutlined';
import ClockCircleOutlined from '@ant-design/icons/ClockCircleOutlined';
import classNames from 'classnames';
import RCPicker from 'rc-picker';
import ContextIsolator from '../../_util/ContextIsolator';
import { useZIndex } from '../../_util/hooks/useZIndex';
import { getMergedStatus, getStatusClassNames } from '../../_util/statusUtils';
import { devUseWarning } from '../../_util/warning';
import { ConfigContext } from '../../config-provider';
import DisabledContext from '../../config-provider/DisabledContext';
import useCSSVarCls from '../../config-provider/hooks/useCSSVarCls';
import useSize from '../../config-provider/hooks/useSize';
import { FormItemInputContext } from '../../form/context';
import useVariant from '../../form/hooks/useVariants';
import { useLocale } from '../../locale';
import { useCompactItemContext } from '../../space/Compact';
import enUS from '../locale/en_US';
import useStyle from '../style';
import { getPlaceholder, transPlacement2DropdownAlign, useIcons } from '../util';
import { MONTH, MONTHPICKER, QUARTER, QUARTERPICKER, TIME, TIMEPICKER, WEEK, WEEKPICKER, YEAR, YEARPICKER, } from './constant';
import useComponents from './useComponents';
const generatePicker = (generateConfig) => {
    const getPicker = (picker, displayName) => {
        const consumerName = displayName === TIMEPICKER ? 'timePicker' : 'datePicker';
        const Picker = forwardRef((props, ref) => {
            var _a;
            const { prefixCls: customizePrefixCls, getPopupContainer: customizeGetPopupContainer, components, style, className, rootClassName, size: customizeSize, bordered, placement, placeholder, popupClassName, dropdownClassName, disabled: customDisabled, status: customStatus, variant: customVariant, onCalendarChange } = props, restProps = __rest(props, ["prefixCls", "getPopupContainer", "components", "style", "className", "rootClassName", "size", "bordered", "placement", "placeholder", "popupClassName", "dropdownClassName", "disabled", "status", "variant", "onCalendarChange"]);
            const { getPrefixCls, direction, getPopupContainer, 
            // Consume different styles according to different names
            [consumerName]: consumerStyle, } = useContext(ConfigContext);
            const prefixCls = getPrefixCls('picker', customizePrefixCls);
            const { compactSize, compactItemClassnames } = useCompactItemContext(prefixCls, direction);
            const innerRef = React.useRef(null);
            const [variant, enableVariantCls] = useVariant('datePicker', customVariant, bordered);
            const rootCls = useCSSVarCls(prefixCls);
            const [wrapCSSVar, hashId, cssVarCls] = useStyle(prefixCls, rootCls);
            useImperativeHandle(ref, () => innerRef.current);
            const additionalProps = {
                showToday: true,
            };
            const mergedPicker = picker || props.picker;
            const rootPrefixCls = getPrefixCls();
            // ==================== Legacy =====================
            const { onSelect, multiple } = restProps;
            const hasLegacyOnSelect = onSelect && picker === 'time' && !multiple;
            const onInternalCalendarChange = (date, dateStr, info) => {
                onCalendarChange === null || onCalendarChange === void 0 ? void 0 : onCalendarChange(date, dateStr, info);
                if (hasLegacyOnSelect) {
                    onSelect(date);
                }
            };
            // =================== Warning =====================
            if (process.env.NODE_ENV !== 'production') {
                const warning = devUseWarning(displayName || 'DatePicker');
                warning(picker !== 'quarter', 'deprecated', `DatePicker.${displayName} is legacy usage. Please use DatePicker[picker='${picker}'] directly.`);
                warning.deprecated(!dropdownClassName, 'dropdownClassName', 'popupClassName');
                warning.deprecated(!('bordered' in props), 'bordered', 'variant');
                warning.deprecated(!hasLegacyOnSelect, 'onSelect', 'onCalendarChange');
            }
            // ===================== Icon =====================
            const [mergedAllowClear, removeIcon] = useIcons(props, prefixCls);
            // ================== components ==================
            const mergedComponents = useComponents(components);
            // ===================== Size =====================
            const mergedSize = useSize((ctx) => { var _a; return (_a = customizeSize !== null && customizeSize !== void 0 ? customizeSize : compactSize) !== null && _a !== void 0 ? _a : ctx; });
            // ===================== Disabled =====================
            const disabled = React.useContext(DisabledContext);
            const mergedDisabled = customDisabled !== null && customDisabled !== void 0 ? customDisabled : disabled;
            // ===================== FormItemInput =====================
            const formItemContext = useContext(FormItemInputContext);
            const { hasFeedback, status: contextStatus, feedbackIcon } = formItemContext;
            const suffixNode = (React.createElement(React.Fragment, null,
                mergedPicker === 'time' ? React.createElement(ClockCircleOutlined, null) : React.createElement(CalendarOutlined, null),
                hasFeedback && feedbackIcon));
            const [contextLocale] = useLocale('DatePicker', enUS);
            const locale = Object.assign(Object.assign({}, contextLocale), props.locale);
            // ============================ zIndex ============================
            const [zIndex] = useZIndex('DatePicker', (_a = props.popupStyle) === null || _a === void 0 ? void 0 : _a.zIndex);
            return wrapCSSVar(React.createElement(ContextIsolator, { space: true },
                React.createElement(RCPicker, Object.assign({ ref: innerRef, placeholder: getPlaceholder(locale, mergedPicker, placeholder), suffixIcon: suffixNode, dropdownAlign: transPlacement2DropdownAlign(direction, placement), placement: placement, prevIcon: React.createElement("span", { className: `${prefixCls}-prev-icon` }), nextIcon: React.createElement("span", { className: `${prefixCls}-next-icon` }), superPrevIcon: React.createElement("span", { className: `${prefixCls}-super-prev-icon` }), superNextIcon: React.createElement("span", { className: `${prefixCls}-super-next-icon` }), transitionName: `${rootPrefixCls}-slide-up`, picker: picker, onCalendarChange: onInternalCalendarChange }, additionalProps, restProps, { locale: locale.lang, className: classNames({
                        [`${prefixCls}-${mergedSize}`]: mergedSize,
                        [`${prefixCls}-${variant}`]: enableVariantCls,
                    }, getStatusClassNames(prefixCls, getMergedStatus(contextStatus, customStatus), hasFeedback), hashId, compactItemClassnames, consumerStyle === null || consumerStyle === void 0 ? void 0 : consumerStyle.className, className, cssVarCls, rootCls, rootClassName), style: Object.assign(Object.assign({}, consumerStyle === null || consumerStyle === void 0 ? void 0 : consumerStyle.style), style), prefixCls: prefixCls, getPopupContainer: customizeGetPopupContainer || getPopupContainer, generateConfig: generateConfig, components: mergedComponents, direction: direction, disabled: mergedDisabled, classNames: {
                        popup: classNames(hashId, cssVarCls, rootCls, rootClassName, popupClassName || dropdownClassName),
                    }, styles: {
                        popup: Object.assign(Object.assign({}, props.popupStyle), { zIndex }),
                    }, allowClear: mergedAllowClear, removeIcon: removeIcon }))));
        });
        if (process.env.NODE_ENV !== 'production' && displayName) {
            Picker.displayName = displayName;
        }
        return Picker;
    };
    const DatePicker = getPicker();
    const WeekPicker = getPicker(WEEK, WEEKPICKER);
    const MonthPicker = getPicker(MONTH, MONTHPICKER);
    const YearPicker = getPicker(YEAR, YEARPICKER);
    const QuarterPicker = getPicker(QUARTER, QUARTERPICKER);
    const TimePicker = getPicker(TIME, TIMEPICKER);
    return { DatePicker, WeekPicker, MonthPicker, YearPicker, TimePicker, QuarterPicker };
};
export default generatePicker;
