import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Spring } from "react-spring/renderprops";
import SeatBelts, { bws } from "../Components/firstView_SeatBelts";
let portalContainer = document.getElementById("modal");

export function renderSeatbeltModal(seatbeltstatus, theme, onBeltFastened) {
  ReactDOM.render(
    <PopUp seatbeltstatus={seatbeltstatus} theme={theme} onBeltFastened={onBeltFastened}></PopUp>,
    portalContainer
  );
}

class PopUp extends Component {
  state = {
    toggle: true,
  };

  onCancel() {
    try {
      bws.close();
      bws.terminate();
      bws.send("close");
    } catch {}

    this.setState({ toggle: false });
    setTimeout(() => {
      ReactDOM.render(null, portalContainer);
    }, 700);
  }

  render() {
    return (
      <Spring
        from={{
          opacity: this.state.toggle ? 0 : 1,
        }}
        to={{
          opacity: this.state.toggle ? 1 : 0,
        }}
        config={{ duration: 500 }}
      >
        {(props) => (
          <div style={{ ...props, ...style.mainContainerStyle }} onClick={this.onCancel.bind(this)}>
            <SeatBelts
              hideModal={this.onCancel.bind(this)}
              onBeltFastened={this.props.onBeltFastened}
              key="seatbelts"
              isModal={true}
              SeatBelt={this.props.seatbeltstatus}
            ></SeatBelts>
          </div>
        )}
      </Spring>
    );
  }
}

const style = {
  text: {
    color: "rgb(230, 230, 230)",
    fontFamily: "Montserrat",
    fontSize: "2vw",
  },
  mainContainerStyle: {
    backdropFilter: "blur(10px)",
    position: "absolute",
    left: 0,
    top: 0,
    zIndex: 1,
    backgroundColor: "rgba(39, 42, 47, 0.8)",
    width: "100vw",
    height: "100vh",
  },
  containerStyle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%,-50%)",
    zIndex: 10,
    width: "50vw",
    height: "min-content",
  },
};
