import React, { Component } from "react";
import { createRoot } from 'react-dom/client';
import { Spring } from "react-spring/renderprops";

export const portalContainer = createRoot(document.getElementById('loading'));

var loadingQue = 0;

export function showLoading(timeout, message, size) {
  return new Promise(function (resolve, reject) {
    loadingQue += 1;
    let reference;
    if (timeout) {
      setTimeout(() => {
        hideLoading();
      }, timeout);
    }
    resolve(reference);
    
    /*ReactDOM.render(
      <Loading
        size={size ? size : null}
        message={message ? message : null}
        ref={(ref) => {
          reference = ref;
        }}
      ></Loading>,
      portalContainer,
      () => {
        if (timeout) {
          setTimeout(() => {
            hideLoading();
          }, timeout);
        }
        resolve(reference);
      }
    );*/
  });
}

export function hideLoading(reference) {
  if (loadingQue >= 1) {
    loadingQue -= 1;
  }
  if (reference !== undefined && loadingQue === 0) {
    reference.setState({ toggle: false });
    setTimeout(() => {
      portalContainer.render(null);
    }, 700);
  }
}

class Loading extends Component {
  state = {
    toggle: true,
  };

  render() {
    return (
      <Spring
        from={{
          opacity: this.state.toggle ? 0 : 1,
        }}
        to={{
          opacity: this.state.toggle ? 1 : 0,
        }}
        config={{ duration: 500 }}
      >
        {(props) => (
          <div style={{ ...props, ...style.mainContainerStyle }}>
            <i
              style={{
                fontSize: this.props.size ? this.props.size + "px" : "12vw",
                color: "#a83e3e",
              }}
              className="fa fa-spinner fa-spin"
            />
            {this.props.message ? <p style={style.textStyle}>{this.props.message}</p> : null}
          </div>
        )}
      </Spring>
    );
  }
}

const style = {
  mainContainerStyle: {
    backdropFilter: "blur(10px)",
    position: "absolute",
    left: 0,
    top: 0,
    zIndex: 20,
    backgroundColor: "rgba(39, 42, 47, 0.8)",
    width: "100vw",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
  },
  textStyle: {
    fontFamily: "Montserrat",
    fontSize: "6vw",
  },
};
