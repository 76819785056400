import React, { Component } from "react";
import { Spring } from "react-spring/renderprops";

export default class ResizeableImage extends Component {
  state = {
    toggle: false,
  };

  render() {
    return (
      <Spring
        from={{
          opacity: this.state.toggle ? 0.8 : 1,

          zIndex: this.state.toggle ? 0 : 1,
          transform: this.state.toggle ? "scale(1,1)" : "scale(1.2,1.2) ",
        }}
        to={{
          opacity: this.state.toggle ? 1 : 0.8,
          zIndex: this.state.toggle ? 1 : 0,
          transform: this.state.toggle ? "scale(1.2,1.2) " : "scale(1,1)",
        }}
      >
        {(props) => (
          <img
            crossOrigin="Anonymous"
            alt={this.props.name}
            onClick={() => {
              this.props.onSelect(this.props.name);
            }}
            onMouseEnter={() => {
              this.setState({ toggle: true });
            }}
            onMouseOut={() => {
              this.setState({ toggle: false });
            }}
            src={this.props.logo}
            style={{
              ...props,
              ...this.props.style,
              pointerEvents: "auto",
              width: "22%",
              margin: "1%",
              objectFit: "contain",
              maxHeight: "48%",
              borderRadius: "30px",
            }}
          />
        )}
      </Spring>
    );
  }
}

/*const styles = {
  p1: {
    color: MAIN_TEXT_COLOR,
    fontFamily: "Montserrat",
    fontSize: "4vw",
  },

  neudiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  adjustButton: {
    width: "8vw",
    height: "8vw",
    marginLeft: "2vw",
    marginRight: "2vw",
    fontSize: "5vw",
    color: MAIN_TEXT_COLOR,
  },
};*/
