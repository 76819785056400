/* eslint-disable no-use-before-define */
import { showLoading, hideLoading } from "../../Common/Loading";
import { connectApi, GET_STATE_FROM_API } from "./ApiActions";
import { notification } from "antd";
import { getIpAddressFromLocalStorage } from "../../Common/Helper";
import { bws } from "../../Components/firstView_SeatBelts";

export const CONNECTED_TCP_SERVER = "CONNECTED_TCP_SERVER";
export const CONNECTION_ERROR_TCP_SERVER = "CONNECTION_ERROR_TCP_SERVER";
export const CLOSED_TCP_SERVER = "CLOSED_TCP_SERVER";
export const NEW_LOG = "NEW_LOG";

let myDispatch;

export const connectTcpServer = (showModal, hideModal) => {
  const { IP_ADDRESS, WEBSOCKET_PORT } = getIpAddressFromLocalStorage();

  return async (dispatch) => {
    showLoading(null, null, 40).then((ref) => {
      try {
        bws.close();
        ws.close();
        ws2.close();
      } catch {}
      var ws = new WebSocket("ws://" + IP_ADDRESS + ":" + WEBSOCKET_PORT + "/ws/SystemStateWebSocket");
      var ws2 = new WebSocket("ws://" + IP_ADDRESS + ":" + WEBSOCKET_PORT + "/ws/EngineAxisWebSocket");// will be Log socket
      let isTwincatConnectionSend = false;

      const timer = setTimeout(function () {
        ws.close();
      }, 10000);

      myDispatch = dispatch;
      ws.onopen = (e) => {
        clearTimeout(timer);
        hideLoading(ref);
        hideModal();
        dispatch({ type: CONNECTED_TCP_SERVER, payload: e.data });
      };

      ws.onmessage = (e) => {
        let data = JSON.parse(e.data);
        if (!isTwincatConnectionSend) {
          if (!data.IsTwinCatConnectionEstablished) {
            isTwincatConnectionSend = true;
            connectApi(showModal);
          }
        }

        dispatch({
          type: GET_STATE_FROM_API,
          payload: data,
        });
      };

      ws.onerror = (e) => {
        try {
          hideLoading(ref);
          showModal();
          ws.close();
        } catch {}
        dispatch({ type: CONNECTION_ERROR_TCP_SERVER });
      };

      ws.onclose = (e) => {
        myDispatch(connectTcpServer(showModal, hideModal));
        dispatch({ type: CLOSED_TCP_SERVER });
      };

      ws2.onmessage = (e) => {
        const data = JSON.parse(e.data);
        const { Message, Type } = data;
        if (Type === 1) {
          notification.warning({
            message: "Warning",
            description: Message,
            placement: "topRight",
          });
        } else if(Type === 0){
          notification.error({
            message: "Error",
            description: Message,
            placement: "topRight",
          });
        } else{
          notification.success({
            message: "Success",
            description: Message,
            placement: "topRight",
          });
        }

        //message.error(data.Message);
        dispatch({ type: NEW_LOG, payload: data });
      };

      ws2.onerror = (e) => {
        ws2.close();
      };
    });
  };
};

export const connectControlPanel = () => {
  const { IP_ADDRESS, WEBSOCKET_PORT } = getIpAddressFromLocalStorage();

  return new Promise((resolve, reject) => {
    var ws = new WebSocket("ws://" + IP_ADDRESS + ":" + WEBSOCKET_PORT + "/control");
    ws.onopen = () => {
      resolve(ws);
    };
    ws.onerror = () => {
      reject(ws);
      notification.error({
        message: "Socket cannot be connected",
        placement: "topRight",
      });
      //message.error("Socket cannot be connected");
    };
  });
};

export const sendMotorCommand = (ref, engine, command, start) => {
  try {
    let data = {
      Command: command,
      Parameters: [engine, String(start)],
    };
    data = JSON.stringify(data);
    ref.send(data);
  } catch {
    notification.error({
      message: "An error occurred about socket.",
      placement: "topRight",
    });
    //message.error("An error occurred about socket");
  }
};
